import { Input, Switch } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

export default function WarehouseMasterList({ warehouseList, getWarehouses }) {
  // const [warehouseList, setWarehouseList] = useState([]);
  const [editingWarehouseId, setEditingWarehouseId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  // For edit forms
  const handleEditWarehouseData = (warehouseId, action = "Edit") => {
    const warehouseDataUpdate = warehouseList.find(
      (wearehousedata) => wearehousedata.FLD_Warehouse_Id === warehouseId
    );

    setFormData({
      warehousename: warehouseDataUpdate.FLD_Warehouse_Name,
      status: warehouseDataUpdate.FLD_Enabled_Flag === "Active",
    });
    if (action === "Delete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this warehouse?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              Notiflix.Loading.dots("");
              handleUpdateWarehouseData(
                warehouseId,
                warehouseDataUpdate,
                "Delete"
              );
            },
          },
          {
            label: "No",
            onClick: async () => {
              setEditingWarehouseId(warehouseId);
            },
          },
        ],
        closeOnClickOutside: false,
      });
    }
    setEditingWarehouseId(warehouseId);
  };

  const handleUpdateWarehouseData = (
    warehouseId,
    updatedWarehouseData = "",
    action = "Update"
  ) => {
    Notiflix.Loading.dots("");
    const updatedWarehouseDataPayload =
      action === "Delete"
        ? {
            warehouseid: warehouseId,
            warehousename: updatedWarehouseData.FLD_Warehouse_Name,
            status: "Deleted",
          }
        : {
            warehouseid: warehouseId,
            warehousename: formData.warehousename,
            status: formData.status ? "Active" : "Inactive",
          };
    PostApiCall.postRequest(
      updatedWarehouseDataPayload,
      "AddWarehouseMaster"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Warehouse master updated successfully.");
          getWarehouses();
        } else {
          Notiflix.Notify.failure("Warehouse master update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For update status (active,inactive)
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredWarehouseMasterList = warehouseList.filter((warehouse) =>
    warehouse.FLD_Warehouse_Name.toLowerCase().includes(
      searchValue.toLowerCase()
    )
  );

  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-3">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>
              List of Warehouse ({" "}
              {
                filteredWarehouseMasterList.filter(
                  (warehouse) => warehouse.FLD_Enabled_Flag !== "Deleted"
                )?.length
              }{" "}
              Nos. )
            </h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Warehouse"
                allowClear
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                className="w-100"
              />
            </div>
          </div>
          <div className="row">
            {filteredWarehouseMasterList?.length > 0 ? (
              filteredWarehouseMasterList
                .filter((warehouse) => warehouse.FLD_Enabled_Flag !== "Deleted")
                ?.map((warehouseList) => (
                  <div className="col-lg-3 mb-4">
                    <div className="spare-card">
                      <div className="row">
                        <div
                          className="col-12 pb-3"
                          key={warehouseList.FLD_Warehouse_Id}
                        >
                          <label>
                            Warehouse Name<span className="text-danger">*</span>
                          </label>
                          <Input
                            size="large"
                            placeholder="Warehouse Name"
                            allowClear
                            className="input-box"
                            name="warehousename"
                            defaultValue={warehouseList.FLD_Warehouse_Name}
                            disabled={
                              editingWarehouseId !==
                              warehouseList.FLD_Warehouse_Id
                            }
                            onChange={handleInputChange}
                          />
                        </div>

                        <div className="col-12">
                          <div className="btn-border mt-2 pt-2">
                            <div className="d-flex align-items-center gap-2">
                              <p className="mb-0">Inactive</p>
                              <Switch
                                checked={
                                  editingWarehouseId ===
                                  warehouseList.FLD_Warehouse_Id
                                    ? formData.status
                                    : warehouseList.FLD_Enabled_Flag ===
                                      "Active"
                                }
                                disabled={
                                  editingWarehouseId !==
                                  warehouseList.FLD_Warehouse_Id
                                }
                                onChange={handleToggleChange}
                              />
                              <p className="mb-0">Active</p>
                            </div>
                            <p className="mb-0 mt-3">
                              Last Edited -
                              {warehouseList.FLD_Updated_on && (
                                <>
                                  {moment(warehouseList.FLD_Updated_on).format(
                                    "DD-MM-YYYY"
                                  )}
                                </>
                              )}
                            </p>
                            <div className="d-flex flex-column gap-3">
                              <Button
                                className="default-btn border-0 mt-3"
                                onClick={() =>
                                  handleEditWarehouseData(
                                    warehouseList.FLD_Warehouse_Id,
                                    "Delete"
                                  )
                                }
                              >
                                Delete Warehouse
                              </Button>
                              <Button
                                className="default-btn border-0"
                                onClick={() =>
                                  editingWarehouseId ===
                                  warehouseList.FLD_Warehouse_Id
                                    ? handleUpdateWarehouseData(
                                        warehouseList.FLD_Warehouse_Id
                                      )
                                    : handleEditWarehouseData(
                                        warehouseList.FLD_Warehouse_Id
                                      )
                                }
                              >
                                {editingWarehouseId ===
                                warehouseList.FLD_Warehouse_Id
                                  ? "Update Warehouse"
                                  : "Edit Warehouse"}
                              </Button>

                              {editingWarehouseId ===
                                warehouseList.FLD_Warehouse_Id && (
                                <Button
                                  className="ml-2 default-btn border-0 mt-3"
                                  onClick={() => setEditingWarehouseId(null)}
                                >
                                  Cancel
                                </Button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
            ) : (
              <p>No Data Available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
