import { Input } from "antd";
import React from "react";
import { Modal } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
import { MdLockReset } from "react-icons/md";
export default function ChangePasswordModal({
  showChangePassword,
  onHideChangePassword,
}) {
  return (
    <>
      <Modal
        centered
        show={showChangePassword}
        onHide={onHideChangePassword}
        className="logout-modal">
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="login-box pb-0">
            <div className="section-title mb-0 mt-0 text-center">
              <div className="login-modal-icon fs-1">
                <MdLockReset />
              </div>
              <h4 className="text-center text-dark mb-3">Change Password</h4>
              {/* <p className="text-dark">???</p> */}
            </div>
            <div>
              <label className="mb-1">Old Password</label>
              <Input.Password size="large" className="mb-3" />
              <label className="mb-1">New Password</label>
              <Input.Password
                size="large"
                className="mb-3"
              />
            </div>
            <div className="text-end">
              <button className="default-btn border-0">Save</button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
