import React from "react";
import UnauthorizedAccessimg from "../../assets/img/403.png";
import { useNavigate } from "react-router-dom";
export default function UnauthorizedAccess() {
  const navigate = useNavigate();
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-lg-6 text-center mx-auto px-0">
            <img src={UnauthorizedAccessimg} className="img-fluid mb-4" />
            <h2 className="text-dark">ACCESS RESTRICTED</h2>
            <p>
              You do not have the required permissions to access this section.
            </p>
            <button
              type="button"
              className="d-block w-auto mx-auto default-btn border-0"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
