var urlString = "https://shivaayintl.com/shivaay-api/";
// var urlString = "http://localhost:3039/shivaay-api/";
const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};
export default GetApiCall;
