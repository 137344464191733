import { DatePicker, Input, message, Switch, Upload } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";

export default function DepartmentMaster() {
  const onChange = (e) => {
    console.log(e);
  };

  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Department</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-12 pb-3">
                  <Input
                    size="large"
                    placeholder="Department Name"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={onChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button className="default-btn border-0 mt-3">
                        Save Department
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="default-card default-card-border mt-2">
              <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                <h4>List of Departments (25 Nos.)</h4>
                <div className="search-box w-50">
                  <Search
                    placeholder="Search Department"
                    allowClear
                    onSearch={onSearch}
                    className="w-100"
                  />
                </div>
              </div>
              <div className="spare-card">
                <div className="row">
                  <div className="col-12 pb-3">
                    <Input
                      size="large"
                      placeholder="Department Name"
                      allowClear
                      className="input-box"
                      onChange={onChange}
                    />
                  </div>

                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch onChange={onChange} />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited - Vishnu (29/08/2024)
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button className="default-btn border-0 mt-3">
                          Delete Department
                        </Button>
                        <Button className="default-btn border-0 mt-3">
                          Edit Department
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
