import React, { useState, useContext, useEffect } from "react";
import { DatePicker, Input, Select, Switch, TimePicker } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import dayjs from "dayjs";
import JobCardListTable from "./JobCardListTable";
import { Link } from "react-router-dom";
import NoImg from "../../assets/img/no-img.jpg";
import { FaArrowRight, FaBoxes, FaCalendarAlt, FaClock } from "react-icons/fa";

// For integrate company form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { store } from "../../context/Store";

export default function CreateJobCard() {
  const [jobcardid, setJobCardId] = useState(null);
  const [status, setStatus] = useState(false);
  const { brandUniqId } = useContext(store);
  // For get items name
  const [itemsname, setItemsName] = useState([]);
  const [selectItemId, setSelectItemID] = useState();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();

  // Send save data
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        jobcardid: jobcardid,
        jobcardnumber: data.jobcardnumber,
        brandid: data.brandid,
        itemid: data.itemid,
        itemqty: data.itemqty,
        color: data.color,
        lightertype: data.lightertype,
        allotedto: data?.allotedto || "",
        allotedby: data.allotedby,
        comments: data.comments,
        allotmentdepartment: data.allotmentdepartment,
        itemqty: data.itemqty,
        status: status ? "Active" : "Inactive",
        action: "Insert",
      },
      "AddJobCard"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Job card registered sucessfully.");
          window.location.reload();
        } else {
          Notiflix.Notify.failure("Job card registration failed!");
        }
      })
    );
  };

  useEffect(() => {
    // Fetch item name
    PostApiCall.postRequest({ whereClause: "" }, "GetitemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setItemsName(
              obj.data.filter((item) => item.FLD_Enabled_Flag == "Active")
            );
          }
        })
    );
  }, []);

  const handleChange = (field) => {
    return (e) => {
      if (e?.target?.value) {
        clearErrors(field);
        setValue(field, e.target.value);
      } else {
        console.log("error");
      }
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Create Job Card</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pb-3">
                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Item Name"
                    {...register("itemid", { required: "Item is required" })}
                    onChange={(value) => {
                      setValue("itemid", value);
                      clearErrors("itemid");
                    }}
                  >
                    {itemsname.map((spare) => (
                      <Select.Option
                        key={spare.FLD_Item_Id}
                        value={spare.FLD_Item_Id}
                      >
                        {spare.FLD_Item_Name}
                      </Select.Option>
                    ))}
                  </Select>
                  {errors.itemid && (
                    <p className="text-danger">{errors.itemid.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity"
                    allowClear
                    className="input-box"
                    id="itemqty"
                    {...register("itemqty", {
                      required: "Quantity is required",
                    })}
                    onChange={handleChange("itemqty")}
                  />
                  {errors.itemqty && (
                    <p className="text-danger">{errors.itemqty.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Choose Brand"
                    {...register("brandid", {
                      required: "Brand is required",
                    })}
                    onChange={(value) => {
                      setValue("brandid", value);
                      clearErrors("brandid");
                    }}
                    options={[
                      { value: "", label: "Choose Brand" },
                      ...(brandUniqId?.map((brand) => ({
                        value: brand?.FLD_Brand_Id,
                        label: brand?.FLD_Brand_Name,
                      })) || []),
                    ]}
                  />
                  {errors.brandid && (
                    <p className="text-danger">{errors.brandid.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <Select
                    size="large"
                    className="input-box"
                    placeholder="Lighter Type"
                    {...register("lightertype", {
                      required: "Lighter type is required",
                    })}
                    onChange={(value) => {
                      setValue("lightertype", value);
                      clearErrors("lightertype");
                    }}
                    options={[
                      { value: "", label: "Choose Item Type" },
                      { value: "Electric", label: "Electric" },
                      { value: "Windproof", label: "Windproof" },
                      { value: "Flint", label: "Flint" },
                    ]}
                  />
                  {errors.lightertype && (
                    <p className="text-danger">{errors.lightertype.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Color"
                    allowClear
                    className="input-box"
                    id="color"
                    {...register("color", {
                      required: "Color is required",
                    })}
                    onChange={handleChange("color")}
                  />
                  {errors.color && (
                    <p className="text-danger">{errors.color.message}</p>
                  )}
                </div>
                {/* <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Allotted To"
                    allowClear
                    className="input-box"
                    id="allotedto"
                    {...register("allotedto", {
                      required: "Alloted to is required",
                    })}
                    onChange={handleChange("allotedto")}
                  />
                  {errors.allotedto && (
                    <p className="text-danger">{errors.allotedto.message}</p>
                  )}
                </div> */}
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Allotted By"
                    allowClear
                    className="input-box"
                    id="allotedby"
                    {...register("allotedby", {
                      required: "Alloted by is required",
                    })}
                    onChange={handleChange("allotedby")}
                  />
                  {errors.allotedby && (
                    <p className="text-danger">{errors.allotedby.message}</p>
                  )}
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Comments"
                    allowClear
                    className="input-box"
                    id="comments"
                    {...register("comments", {
                      required: "Comments is required",
                    })}
                    onChange={handleChange("comments")}
                  />
                  {errors.comments && (
                    <p className="text-danger">{errors.comments.message}</p>
                  )}
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save & Send to Production
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="d-none d-lg-block">
              <JobCardListTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
