import React, { useState } from "react";
import "./SideNavbar.css";
import { Sidebar } from "react-pro-sidebar";
import ManagerSideNvbar from "./ManagerSideNvbar";
import SupervisorSideNavbar from "./SupervisorSideNavbar";

export const SideNavbar = ({ collapsed, handleSidebarCollapse }) => {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));

  return (
    <>
      <div className="sidenavbar">
        {login[0]?.FLD_User_Type !== 3 ? (
          <Sidebar collapsed={collapsed}>
            <SupervisorSideNavbar handleCollapse={handleSidebarCollapse} />
          </Sidebar>
        ) : (
          <Sidebar collapsed={collapsed}>
            <ManagerSideNvbar handleCollapse={handleSidebarCollapse} />
          </Sidebar>
        )}
      </div>
    </>
  );
};
