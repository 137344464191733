import { DatePicker, Input, Select, Switch, TimePicker } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import React, { useState } from "react";
import dayjs from "dayjs";
const PickerWithType = ({ type, onChange }) => {
  if (type === "time") return <TimePicker onChange={onChange} />;
  if (type === "date") return <DatePicker onChange={onChange} />;
  return <DatePicker picker={type} onChange={onChange} />;
};
const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export default function JobMovementRecord() {
  const onChange = (e) => {
    console.log(e);
  };
  const [type, setType] = useState("time");
  const onSearch = (value, _e, info) => console.log(info?.source, value);

  const label = { inputProps: { "aria-label": "Switch demo" } };

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Job Movement Record</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Card No."
                    disabled
                    value={"Job Card No. (123)"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Movement Inward Date & Time"
                    disabled
                    value={"Job Movement Inward Date & Time"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Movement Outward Date & Time"
                    disabled
                    value={"Job Movement Outward Date & Time"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="From Department"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="To Department"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Approved"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Rejected"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Repairable"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Dead Stock"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Warehouse ID"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={onChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button className="default-btn border-0 mt-3">
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="default-card default-card-border mt-2">
              <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                <h4>List of Job Movement Records (25 Nos.)</h4>
                <div className="search-box w-50">
                  <Search
                    placeholder="Search Job Movement"
                    allowClear
                    onSearch={onSearch}
                    className="w-100"
                  />
                </div>
              </div>
              <div className="spare-card">
                <div className="row">
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Card No."
                    disabled
                    value={"Job Card No. (123)"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Movement Inward Date & Time"
                    disabled
                    value={"Job Movement Inward Date & Time"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Job Movement Outward Date & Time"
                    disabled
                    value={"Job Movement Outward Date & Time"}
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="From Department"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="To Department"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Approved"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Rejected"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Repairable"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Quantity Dead Stock"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>
                <div className="col-lg-4 pb-3">
                  <Input
                    size="large"
                    placeholder="Warehouse ID"
                    allowClear
                    className="input-box"
                    onChange={onChange}
                  />
                </div>

                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch onChange={onChange} />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited - Vishnu (29/08/2024)
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button className="default-btn border-0 mt-3">
                          Delete
                        </Button>
                        <Button className="default-btn border-0 mt-3">
                          Edit
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
