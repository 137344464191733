import { Input } from "antd";

export default function PacakgingInputSection(onChange) {
  return (
    <div className="row">
      <div className="col-lg-3 mb-3">
        <Input
          size="large"
          placeholder="Quantity In"
          allowClear
          className="input-box"
          onChange={onChange}
        />
      </div>
      <div className="col-lg-3 mb-3">
        <Input
          size="large"
          placeholder="Packed Boxes"
          allowClear
          className="input-box"
          onChange={onChange}
        />
      </div>
      <div className="col-lg-3 mb-3">
        <Input
          size="large"
          placeholder="Packed Cartons"
          allowClear
          className="input-box"
          onChange={onChange}
        />
      </div>
      <div className="col-lg-3 mb-3">
        <Input
          size="large"
          placeholder="Dispatched To"
          allowClear
          className="input-box"
          onChange={onChange}
        />
      </div>
    </div>
  );
}
