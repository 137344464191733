import React from "react";
import pagenotimg from "../../assets/img/404error.png";
import { useNavigate } from "react-router-dom";
const PageNotFound = () => {
  const navigate = useNavigate();
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-lg-8 text-center mx-auto px-0">
            <img src={pagenotimg} className="img-fluid" />
            <h2 className="text-dark">PAGE NOT FOUND</h2>
            <p>The Page you are looking for doesn't exist.</p>
            <button
              type="button"
              className="d-block w-auto mx-auto default-btn border-0"
              onClick={() => navigate(-1)}
            >
              Go Back
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageNotFound;
