import React from "react";
import NoImg from "../../assets/img/no-img.jpg";
import { Checkbox, Collapse, Input } from "antd";
const { Panel } = Collapse;
export default function TrayShifting() {
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <div className="margin-left blank-section tray-shifting-page job-card-page">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-lg-4 px-0 px-lg-2 mb-3">
            <Collapse accordion>
              <Panel
                header={
                  <div className="job-card-box p-0 border-0">
                    <div className="box border-0 mb-2">
                      <div className="d-flex align-items-center">
                        <img src={NoImg} className="img-fluid" />
                        <p className="card-number">SHIW-031224-RED01</p>
                      </div>
                    </div>
                    <div className="box content">
                      <p>
                        <span>Total Quantity : </span>
                        10,000
                      </p>
                    </div>
                    <div className="box content">
                      <p>
                        <span>Current Quantity : </span>
                        10,000
                      </p>
                    </div>
                    <div className="tray-checkbox">
                      <Checkbox
                        onChange={onChange}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </div>
                }
                key="1">
                <div className="job-card-box p-0 border-0">
                  <div className="stage-content">
                    <div className="box">
                      <span>Quantity In :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box">
                      <span>Dead Stock :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box border-0">
                      <span>Repairable :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box border-0">
                      <span>Quantity Out :</span>
                      <p>10,000</p>
                    </div>
                  </div>
                  <div className="stage-input">
                    <div className="box">
                      <span>Quantity In :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box">
                      <span>Dead Stock</span>
                      <Input allowClear className="input-box" />
                    </div>
                    <div className="box">
                      <span>Repairable</span>
                      <Input allowClear className="input-box" />
                    </div>

                    <div className="mt-3 text-end">
                      <span className="default-btn">Save</span>
                    </div>
                  </div>
                </div>
              </Panel>
              <Panel
                header={
                  <div className="job-card-box p-0 border-0">
                    <div className="box border-0 mb-2">
                      <div className="d-flex align-items-center">
                        <img src={NoImg} className="img-fluid" />
                        <p className="card-number">SHIW-031224-RED01</p>
                      </div>
                    </div>
                    <div className="box content">
                      <p>
                        <span>Total Quantity : </span>
                        10,000
                      </p>
                    </div>
                    <div className="box content">
                      <p>
                        <span>Current Quantity : </span>
                        10,000
                      </p>
                    </div>
                    <div className="tray-checkbox">
                      <Checkbox
                        onChange={onChange}
                        onClick={(e) => e.stopPropagation()}
                      />
                    </div>
                  </div>
                }
                key="2">
                <div className="job-card-box p-0 border-0">
                  <div className="stage-content">
                    <div className="box">
                      <span>Quantity In :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box">
                      <span>Dead Stock :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box border-0">
                      <span>Repairable :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box border-0">
                      <span>Quantity Out :</span>
                      <p>10,000</p>
                    </div>
                  </div>
                  <div className="stage-input">
                    <div className="box">
                      <span>Quantity In :</span>
                      <p>10,000</p>
                    </div>
                    <div className="box">
                      <span>Dead Stock</span>
                      <Input allowClear className="input-box" />
                    </div>
                    <div className="box">
                      <span>Repairable</span>
                      <Input allowClear className="input-box" />
                    </div>

                    <div className="mt-3 text-end">
                      <span className="default-btn">Save</span>
                    </div>
                  </div>
                </div>
              </Panel>
            </Collapse>
            <div className="tray-shifting-floting-btn">
                <span className="default-btn">Create Lot</span>
            </div>
            {/* <div className="job-card-box">
              <div className="box border-0 mb-2 position-relative">
                <div className="d-flex align-items-center">
                  <img src={NoImg} className="img-fluid" />
                  <p className="card-number">SHIW-031224-RED01</p>
                </div>
                <div className="tray-checkbox">
                  <Checkbox onChange={onChange} />
                </div>
              </div>
              <div className="box content">
                <p>
                  <span>Total Quantity : </span>
                  10,000
                </p>
              </div>
              <div className="box content">
                <p>
                  <span>Current Quantity : </span>
                  10,000
                </p>
              </div>
              <div className="stage-content mt-3">
                <div className="box">
                  <span>Quantity In :</span>
                  <p>10,000</p>
                </div>
                <div className="box">
                  <span>Dead Stock :</span>
                  <p>10,000</p>
                </div>
                <div className="box border-0">
                  <span>Repairable :</span>
                  <p>10,000</p>
                </div>
                <div className="box border-0">
                  <span>Quantity Out :</span>
                  <p>10,000</p>
                </div>
              </div>
              <div className="stage-input">
                <div className="box">
                  <span>Quantity In :</span>
                  <p>10,000</p>
                </div>
                <div className="box">
                  <span>Dead Stock</span>
                  <Input allowClear className="input-box" />
                </div>
                <div className="box">
                  <span>Repairable</span>
                  <Input allowClear className="input-box" />
                </div>

                <div className="mt-3 text-end">
                  <span className="default-btn">Save</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
