import React, { useEffect, useState } from "react";
import "./SideNavbar.css";
import { Link } from "react-router-dom";
import { Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { BsFillHouseDoorFill, BsHouseGearFill } from "react-icons/bs";
import { FaRectangleList } from "react-icons/fa6";

export default function SupervisorSideNavbar({handleCollapse}) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    // cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const targetPath =
    windowWidth > 568 ? "/spares-request-form" : "/spare-request-list";
  return (
    <Menu>
      <Link to={"/supervisor-dashboard"} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <BsFillHouseDoorFill className="sidebar-icon" /> Dashboard
        </MenuItem>
      </Link>
      <SubMenu
        label="Production Management"
        className="sidebar-menuitem"
        icon={<BsHouseGearFill className="sidebar-icon" />}
      >
        {/* <MenuItem>
          {" "}
          <Link to={"/job-card-list"}>Job Movement Record</Link>
        </MenuItem> */}
        <MenuItem>
          {" "}
          <Link to={"/active-job-card-list"} onClick={() => handleCollapse()}>Active Job Cards</Link>
        </MenuItem>
      </SubMenu>
      <Link to={targetPath} onClick={() => handleCollapse()}>
        <MenuItem className="sidebar-menuitem">
          <FaRectangleList className="sidebar-icon" /> Spare Request
        </MenuItem>
      </Link>
    </Menu>
  );
}
