import moment from "moment";
import React from "react";
export const Footer = () => {
  return (
    <>
      <div className="margin-left">
        <div className="container-fluid">
          <div className="row default-card inner-footer">
            <div className="col-md-6">
              <p className="mb-0 footer">
                Powered by{" "}
                <a
                  href="http://globaltrendz.com/"
                  target="_blank"
                  className="color-theme">
                  {" "}
                  Global Trendz.
                </a>
              </p>
            </div>
            <div className="col-md-6 text-lg-end">
              <p className="mb-0 footer">
                Copyrights © {moment().format("YYYY")} Shivaay International.<br className="d-block d-lg-none" /> All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
