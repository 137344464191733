import React, { useState, useEffect, useContext } from "react";
import "./ItemManagement.css";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Upload, Select, Switch } from "antd";
import Search from "antd/es/input/Search";

// For integrate item form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";
import { store } from "../../context/Store";

// For get uploaded image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function ItemMasterList({ itemList, getItems }) {
  const [editingItemId, setEditingItemId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");
  const { brandUniqId } = useContext(store);
  // For image
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );

  // For edit forms
  const handleEditItem = (itemId, action = "Edit") => {
    const itemsUpdate = itemList.find((items) => items.FLD_Item_Id === itemId);
    setFormData({
      itemname: itemsUpdate.FLD_Item_Name,
      brandid: itemsUpdate.FLD_Brand_Id,
      image: itemsUpdate.FLD_Item_Image,
      itemtype: itemsUpdate.FLD_Type,
      unit: itemsUpdate.FLD_Unit,
      weight: itemsUpdate.FLD_Weight,
      stockinhand: itemsUpdate.FLD_Stock_In_Hand,
      status: itemsUpdate.FLD_Enabled_Flag === "Active",
    });
    if (action === "Delete") {
      confirmAlert({
        title: "Confirm to Delete",
        message: "Are you sure you want to delete this item?",
        buttons: [
          {
            label: "Yes",
            onClick: async () => {
              Notiflix.Loading.dots("");
              handleUpdateItem(itemId, itemsUpdate, (action = "Delete"));
            },
          },
          {
            label: "No",
            onClick: async () => {
              setEditingItemId(null);
            },
          },
        ],
        closeOnClickOutside: false,
      });
    }
    setEditingItemId(itemId);
  };

  const handleUpdateItem = (itemId, itemsUpdate = "", action = "Update") => {
    Notiflix.Loading.dots("");
    const updateItemPayload =
      action == "Delete"
        ? {
            itemid: itemId,
            itemname: itemsUpdate.FLD_Item_Name,
            brandid: itemsUpdate.FLD_Brand_Id,
            image: imageUrl === "" ? itemsUpdate.FLD_Item_Image : imageUrl,
            itemtype: itemsUpdate.FLD_Type,
            unit: itemsUpdate.FLD_Unit,
            weight: itemsUpdate.FLD_Weight,
            stockinhand: itemsUpdate.FLD_Stock_In_Hand,
            status: "Deleted",
          }
        : {
            itemid: itemId,
            itemname: formData.itemname,
            brandid: formData.brandid,
            image: imageUrl === "" ? formData.image : imageUrl,
            itemtype: formData.itemtype,
            unit: formData.unit,
            weight: formData.weight,
            stockinhand: formData.stockinhand,
            status: formData.status ? "Active" : "Inactive",
          };
    PostApiCall.postRequest(updateItemPayload, "AddItem").then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Item updated successfully.");
          setEditingItemId(null);
          getItems();
        } else {
          Notiflix.Notify.failure("Item update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredItemList = itemList.filter((item) =>
    item.FLD_Item_Name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleDeleteItem = (itemId) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this item?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            Notiflix.Loading.dots("");
            try {
              const results = await PostApiCall.postRequest(
                {
                  itemid: `${itemId}`,
                  status: "Deleted",
                },
                "DeleteItem"
              );
              if (results.status === 200 || results.status === 201) {
                Notiflix.Notify.success("Item successfully deleted.");
                // setItemList((prevList) =>
                //   prevList.filter((item) => item.FLD_Item_Id !== itemId)
                // );
              } else {
                Notiflix.Notify.failure("Failed to delete the Item.");
              }
            } catch (error) {
              Notiflix.Notify.failure("An error occurred: " + error.message);
            } finally {
              Notiflix.Loading.remove();
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };

  // Handel image on server

  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Company Logo
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "itemMasterImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setImageUrl("");
  }, [editingItemId]);

  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-0 ">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>
              List of Items (
              {
                filteredItemList?.filter(
                  (item) => item.FLD_Enabled_Flag !== "Deleted"
                ).length
              }{" "}
              Nos.)
            </h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Items"
                allowClear
                className="w-100"
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>

          {filteredItemList?.length > 0 ? (
            filteredItemList
              .filter((item) => item.FLD_Enabled_Flag !== "Deleted")
              ?.map((item) => (
                <div className="spare-card">
                  <div className="row">
                    <div className="col-lg-2 pb-3" key={item.FLD_Item_Id}>
                      <Upload
                        disabled={editingItemId !== item.FLD_Item_Id}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader w-100"
                        showUploadList={false}
                        customRequest={onaction}
                        beforeUpload={beforeUpload}
                      >
                        {editingItemId !== item.FLD_Item_Id ? (
                          <img
                            src={item.FLD_Item_Image}
                            alt="avatar"
                            style={{
                              width: "100%",
                            }}
                          />
                        ) : item.FLD_Item_Image !== "" && imageUrl === "" ? (
                          <>
                            {
                              <img
                                src={item.FLD_Item_Image}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                }}
                              />
                            }
                          </>
                        ) : (
                          <>
                            {imageUrl === "" ? (
                              uploadButton
                            ) : (
                              <img
                                src={imageUrl}
                                alt="avatar"
                                style={{
                                  width: "100%",
                                }}
                              />
                            )}
                          </>
                        )}
                      </Upload>
                    </div>
                    <div className="col-lg-10">
                      <div className="row">
                        <div className="col-lg-4 pb-3">
                          <label for="itemname">
                            Item Name <span className="text-danger">*</span>
                          </label>
                          <Input
                            size="large"
                            // placeholder="Item Name"
                            allowClear
                            className="input-box"
                            name="itemname"
                            defaultValue={item.FLD_Item_Name}
                            disabled={editingItemId !== item.FLD_Item_Id}
                            onChange={handleInputChange}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label for="itemtype">
                            Item Type<span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="Item Type"
                            name="itemtype"
                            defaultValue={item.FLD_Type}
                            disabled={editingItemId !== item.FLD_Item_Id}
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                itemtype: value,
                              }));
                            }}
                            options={[
                              { value: "Electric", label: "Electric" },
                              { value: "Windproof", label: "Windproof" },
                              { value: "Flint", label: "Flint" },
                            ]}
                          />
                        </div>
                        <div className="col-lg-4 pb-3">
                          <label for="brandid">
                            Brand <span className="text-danger">*</span>
                          </label>
                          <Select
                            size="large"
                            className="input-box"
                            placeholder="Brand"
                            name="brandid"
                            defaultValue={item.FLD_Brand_Id}
                            disabled={editingItemId !== item.FLD_Item_Id}
                            onChange={(value) => {
                              setFormData((prevData) => ({
                                ...prevData,
                                brandid: value,
                              }));
                            }}
                            options={[
                              ...(brandUniqId?.map((brand) => ({
                                value: brand?.FLD_Brand_Id,
                                label: brand?.FLD_Brand_Name,
                              })) || []),
                            ]}
                          />
                        </div>

                        {/* <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Item Unit"
                          allowClear
                          className="input-box"
                          name="unit"
                          defaultValue={item.FLD_Unit}
                          disabled={editingItemId !== item.FLD_Item_Id}
                          onChange={handleInputChange}
                        />
                      </div> */}
                        {/* <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Weight"
                          allowClear
                          className="input-box"
                          name="weight"
                          defaultValue={item.FLD_Weight}
                          disabled={editingItemId !== item.FLD_Item_Id}
                          onChange={handleInputChange}
                        />
                      </div> */}

                        {/* <div className="col-lg-4 pb-3">
                        <Input
                          size="large"
                          placeholder="Stock In Hand"
                          allowClear
                          className="input-box"
                          name="stockinhand"
                          defaultValue={item.FLD_Stock_In_Hand}
                          disabled={editingItemId !== item.FLD_Item_Id}
                          onChange={handleInputChange}
                        />
                      </div> */}
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center gap-2">
                          <p className="mb-0">Inactive</p>
                          <Switch
                            checked={
                              editingItemId === item.FLD_Item_Id
                                ? formData.status
                                : item.FLD_Enabled_Flag === "Active"
                            }
                            disabled={editingItemId !== item.FLD_Item_Id}
                            onChange={handleToggleChange}
                          />
                          <p className="mb-0">Active</p>
                          <p className="mb-0 ms-3">
                            Last Edited -
                            {item.FLD_Updated_on && (
                              <>
                                {moment(item.FLD_Updated_on).format(
                                  "DD-MM-YYYY"
                                )}
                              </>
                            )}
                          </p>
                        </div>

                        <div className="d-flex gap-3">
                          <Button
                            className="default-btn border-0 mt-3"
                            onClick={() =>
                              handleEditItem(item.FLD_Item_Id, "Delete")
                            }
                          >
                            Delete Item
                          </Button>
                          <Button
                            className="default-btn border-0 mt-3"
                            onClick={() =>
                              editingItemId === item.FLD_Item_Id
                                ? handleUpdateItem(item.FLD_Item_Id)
                                : handleEditItem(item.FLD_Item_Id)
                            }
                          >
                            {editingItemId === item.FLD_Item_Id
                              ? "Update Item"
                              : "Edit Item"}
                          </Button>

                          {editingItemId === item.FLD_Item_Id && (
                            <Button
                              className="ml-2 default-btn border-0 mt-3"
                              onClick={() => setEditingItemId(null)}
                            >
                              Cancel
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
    </div>
  );
}
