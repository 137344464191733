import React, { useEffect, useState } from "react";
import "./TopHeader.css";
import { HiShoppingCart } from "react-icons/hi";
import { BsPersonLinesFill } from "react-icons/bs";
import { BiSolidReceipt, BiSolidReport } from "react-icons/bi";
import { PiReceiptFill } from "react-icons/pi";
import { FaTruckMoving, FaUser } from "react-icons/fa";
import { Swiper, SwiperSlide } from "swiper/react";
import GetApiCall from "../../GetApi";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { Pagination } from "swiper/modules";
import ClientLogo from "../../assets/img/client-logo.jpg";
export const TopHeader = () => {
  const [Totalcustomers, setTotalCustomers] = useState([]);
  const [TotalProducts, setTotalProducts] = useState([]);
  const [TotalQuotations, setTotalQuotations] = useState([]);
  const [TotalOrders, setTotalOrders] = useState([]);
  const [TotalDispatchedOrders, setTotalDispatchedOrders] = useState([]);
  const [TotalSalesLeads, setTotalSalesLeads] = useState([]);
  const [TotalUsers, setTotalUsers] = useState([]);

  // useEffect(() => {
  //   GetApiCall.getRequest("/GetDashboardCount")
  //     .then((result) => result.json())
  //     .then((obj) => {
  //       setTotalCustomers(obj.data[0].TotalCustomers);
  //       setTotalProducts(obj.data[0].TotalProducts);
  //       setTotalQuotations(obj.data[0].TotalQuotations);
  //       setTotalOrders(obj.data[0].TotalOrders);
  //       setTotalDispatchedOrders(obj.data[0].TotalDispatchedOrders);
  //       setTotalUsers(obj.data[0].TotalUsers);
  //       setTotalSalesLeads(obj.data[0].OrdersThisMonth);
  //     });
  // }, []);

  return (
    <>
      <div className="margin-left">
        <div className="container-fluid pt-5">
          <div className="row pt-4 mb-4">
            <div className="col-12 mt-3 mt-lg-4 px-0">
              {/* <div className="top-header pb-0">              */}
              {/* <Swiper
                  slidesPerView={1}
                  spaceBetween={10}
                  loop={true}
                  pagination={{
                    clickable: true,
                  }}
                  breakpoints={{
                    640: {
                      slidesPerView: 1,
                      spaceBetween: 20,
                    },
                    768: {
                      slidesPerView: 3,
                      spaceBetween: 40,
                    },
                    1024: {
                      slidesPerView: 5,
                      spaceBetween: 10,
                    },
                  }}
                  modules={[Pagination]}
                  className="mySwiper top-header-swiper"
                >
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <BsPersonLinesFill />
                      </div>
                      <div>
                        <span>Customers</span>
                        <p>{Totalcustomers}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <HiShoppingCart />
                      </div>
                      <div>
                        <span>Products</span>
                        <p>{TotalProducts}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <BiSolidReceipt />
                      </div>
                      <div>
                        <span>Quotations</span>
                        <p>{TotalQuotations}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <PiReceiptFill />
                      </div>
                      <div>
                        <span>Orders</span>
                        <p>{TotalOrders}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <FaTruckMoving />
                      </div>
                      <div>
                        <span>Dispatched Orders</span>
                        <p>{TotalDispatchedOrders}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <BiSolidReport />
                      </div>
                      <div>
                        <span>Sales Leads</span>
                        <p>{TotalSalesLeads}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="top-header-card">
                      <div className="top-header-icon">
                        <FaUser />
                      </div>
                      <div>
                        <span>Users</span>
                        <p>{TotalUsers}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper> */}

              <div className="top-header-box">
                <div className="top-header-card top-header">
                  <div className="top-header-icon">
                    <BsPersonLinesFill />
                  </div>
                  <div>
                    <span>Brands</span>
                    <p>2</p>
                  </div>
                </div>
                <div className="top-header-card top-header">
                  <div className="top-header-icon">
                    <BsPersonLinesFill />
                  </div>
                  <div>
                    <span>Customers</span>
                    <p>12345</p>
                  </div>
                </div>
                <div className="top-header-card top-header">
                  <div className="top-header-icon">
                    <BsPersonLinesFill />
                  </div>
                  <div>
                    <span>Job Cards</span>
                    <p>21</p>
                  </div>
                </div>
                <div className="top-header-card top-header">
                  <div className="top-header-icon">
                    <BsPersonLinesFill />
                  </div>
                  <div>
                    <span>Orders</span>
                    <p>123454</p>
                  </div>
                </div>
                <div className="top-header-card top-header">
                  <div className="top-header-icon">
                    <BsPersonLinesFill />
                  </div>
                  <div>
                    <span>Employees</span>
                    <p>12345</p>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
            {/* <div className="col-lg-3 mt-4 pe-lg-0">
              <div className="top-header client-logo">
                <img src={ClientLogo} className="img-fluid" />
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};
