import {
  Checkbox,
  Collapse,
  Drawer,
  Input,
  Modal,
  Select,
  Statistic,
  notification,
} from "antd";
import { FaIdCard } from "react-icons/fa";
import { IoIosWarning } from "react-icons/io";
import PostApiCall from "../../PostApi";
import "./ProductionManagement.css";
import { useEffect, useState } from "react";
import Notiflix from "notiflix";
import { MdWarning } from "react-icons/md";
import Projections from "./Projections";
import NoImg from "../../assets/img/no-img.jpg";
import { IoClose } from "react-icons/io5";
const { Countdown } = Statistic;
const { Panel } = Collapse;
export function JobCardAccordion() {
  const [jobCardData, setJobCartData] = useState([]);
  const [filteredCardData, setFilteredCardData] = useState([]);
  const [deadstock, setDeadStock] = useState(null);
  const [repairable, setRepairable] = useState(null);
  const [isCritical, setIsCritical] = useState(false);
  const [deptDropdown, setDeptDropdown] = useState([]);
  const [open, setOpen] = useState(false);
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const onChange = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    getJobCards();
    getDepartmentData();
  }, []);

  const handlePanelChange = (key) => {
    console.log("Active Panel Key:", key);
  };

  const getDepartmentData = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetDepartment").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setDeptDropdown(obj.data);
          }
        })
    );
  };

  const getJobCards = () => {
    try {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          wherecaluse: "",
        },
        "GetActiveJobCards"
      ).then((results) =>
        results.json().then((obj) => {
          Notiflix.Loading.remove();
          if (results.status === 200 || results.status === 201) {
            setJobCartData(obj.data);
            setFilteredCardData(
              login[0]?.FLD_User_Name != "Admin"
                ? obj.data.filter(
                    (card) => card?.active_stage == login[0]?.FLD_User_Name
                  )
                : obj.data
            );
            Notiflix.Loading.remove();
          } else {
            Notiflix.Loading.remove();
            Notiflix.Notify.failure("Something went wrong");
          }
        })
      );
    } catch (error) {
      console.log(error);
      Notiflix.Loading.remove();
    }
  };

  const [modal2Open, setModal2Open] = useState(false);
  const UpdateJobCardApprovalHistory = (
    jobCardId,
    cardApprovalId,
    criticalStatus
  ) => {
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        jobcardapprovalid: cardApprovalId,
        status: "Accepted",
        criticalStatus: criticalStatus,
      },
      "UpdateJobCardApprovalHistory"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          console.log("Success");
          getJobCards(); // get updated card status
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const UpdateJobCardStage = (jobCardId, deptId) => {
    PostApiCall.postRequest(
      {
        jobcardid: jobCardId,
        actionby: 2,
        department: deptId,
        deadstock: deadstock,
        repairable: repairable,
        action: "closed",
        comments: "",
      },
      "UpdateJobCardStage"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          console.log("Success");
          Notiflix.Notify.success("Job card updated successfully!");
          setDeadStock(null);
          setRepairable(null);
          getJobCards(); // get updated card status
        } else {
          Notiflix.Notify.failure("Something went wrong!");
        }
      })
    );
  };

  const calculateCountdownTime = (actionTime) => {
    if (!actionTime) {
      return 0; // Return 0 if actionTime is null, undefined, or invalid
    }

    try {
      const actionDate = new Date(actionTime);
      if (isNaN(actionDate.getTime())) {
        return 0; // Return 0 if the date is invalid
      }
      const endTime = new Date(actionDate.getTime() + 30 * 60 * 1000); // Add 6 hours
      const currentTime = Date.now(); // Current time
      const remainingTime = endTime.getTime() - currentTime; // Remaining time
      return remainingTime > 0 ? remainingTime : 0; // Prevent negative time
    } catch (error) {
      console.error("Error calculating countdown time:", error);
      return 0; // Return 0 in case of an error
    }
  };
  const handleChange = (value) => {
    setFilteredCardData(
      jobCardData.filter((card) => card?.active_stage == value)
    );
    console.log(`selected ${value}`);
  };
  return (
    <>
      <div className="margin-left blank-section job-card-accordion">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            {/* Common dropdown for cards and projections */}
            <div className="mb-2 d-flex justify-content-end">
              <Select
                defaultValue={
                  login[0].FLD_User_Name != "Admin" ? login[0].FLD_User_Name : "List All"
                }
                style={{ width: 180 }}
                onChange={handleChange}
              >
                {deptDropdown
                  .sort((a, b) => a.Fld_Sequence - b.Fld_Sequence)
                  .map((dept) => (
                    <Select.Option
                      key={dept.FLD_Department_Id}
                      value={dept.FLD_Department_Name}
                    >
                      {dept.FLD_Department_Name}
                    </Select.Option>
                  ))}
              </Select>
            </div>
            {/* show projections only in tray shifting stage */}
            {(login[0].FLD_User_Name === "Tray Shifting" ||
              login[0].FLD_User_Name === "Packaging") && <Projections />}
            <div className="col-12 mb-4 px-0 px-lg-2">
              <Collapse
                onChange={handlePanelChange}
                accordion
                // defaultActiveKey="1"
              >
                {filteredCardData.map((job, jobIndex) => {
                  const stages = JSON.parse(job.jobcard_stages)?.stages || [];
                  const { active_stage, FLD_Current_Action_Time } = job;
                  let countdownTime = 0;
                  if (
                    active_stage === "Flame Testing 1" ||
                    active_stage === "Flame Testing 2"
                  ) {
                    countdownTime = calculateCountdownTime(
                      FLD_Current_Action_Time
                    );
                  }
                  const criticalStage = stages.find(
                    (stage) =>
                      stage.fld_pending_with === active_stage &&
                      stage.FLD_IsCritical === "Yes"
                  );
                  if (
                    criticalStage &&
                    criticalStage.FLD_IsCritical === "Yes" &&
                    !isCritical
                  ) {
                    setIsCritical(true);
                  }
                  return (
                    <Panel
                      key={jobIndex}
                      header={
                        <>
                          <div className="d-lg-flex justify-content-between px-3 py-2">
                            <p className="card-number">
                              <div className="d-flex align-items-center">
                                <img
                                  src={job?.item_image}
                                  className="img-fluid"
                                />
                                {job.FLD_Job_Card_Number}
                              </div>
                              <span
                                style={{
                                  display: stages.some(
                                    (stage) =>
                                      stage.fld_pending_with === active_stage &&
                                      stage.FLD_IsCritical === "Yes"
                                  )
                                    ? "flex"
                                    : "none",
                                }}
                                className="align-items-center gap-2"
                              >
                                <MdWarning className="text-warning" />
                                <p>
                                  This job card was accepted at {active_stage}{" "}
                                  before 6 hours
                                </p>
                              </span>
                            </p>
                          </div>
                          <div className="mt-2 border-top pt-2 px-3 inner-accordion-header">
                            <p>
                              <span>Total Quantity : </span>
                              {job.FLD_Item_Qty}
                            </p>
                            <p>
                              <span>Current Quantity : </span>
                              {job.FLD_Current_Quantity}
                            </p>
                            <p>
                              <span>Stage : </span>
                              {job.active_stage || "Flame Test 1"}
                            </p>

                            <Countdown
                              style={{
                                display:
                                  countdownTime > 0 &&
                                  (active_stage === "Flame Testing 1" ||
                                    active_stage === "Flame Testing 2") &&
                                  isCritical
                                    ? "flex"
                                    : "none",
                              }}
                              title="This Batch is currently on hold for the remaining duration"
                              value={Date.now() + countdownTime} // Set the deadline dynamically
                              className="countdown w-100 justify-content-lg-end"
                              onFinish={() =>
                                notification.success({
                                  message: "Countdown Completed!",
                                })
                              }
                              format="HH:mm:ss"
                            />
                          </div>
                          {/* <div className="tray-checkbox">
                            <Checkbox
                              onChange={onChange}
                              onClick={(e) => e.stopPropagation()}
                            />
                          </div> */}
                        </>
                      }
                    >
                      <Collapse className="inner-collapse" accordion>
                        {stages.map((stage, stageIndex) => {
                          return stage.fld_action === "closed" ? (
                            <Panel
                              key={stageIndex}
                              header={
                                <div className="px-3 py-2 mt-1">
                                  <p>{stage.fld_pending_with}</p>
                                </div>
                              }
                            >
                              <div className="stage-card">
                                <span className="top-text">
                                  The details of the batch of lighters processed
                                  at this stage are as follows:
                                </span>
                                <div className="stage-content">
                                  <div className="box">
                                    <span>Quantity In :</span>
                                    <p>{stage.FLD_Item_Qty_In}</p>
                                  </div>
                                  <div className="box">
                                    <span>Dead Stock :</span>
                                    <p>{stage.FLD_Dead_Stock_Qty_In}</p>
                                  </div>
                                  <div className="box border-0">
                                    <span>Repairable :</span>
                                    <p>{stage.FLD_Repairable_Qty}</p>
                                  </div>
                                  <div className="box border-0">
                                    <span>Quantity Out :</span>
                                    <p>{stage.FLD_Item_Qty_Out}</p>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          ) : (
                            <Panel
                              key={stageIndex}
                              header={
                                <div className="px-3 py-2 mt-1 d-flex">
                                  <p>{stage.fld_pending_with}</p>
                                  <span
                                    className="ms-2"
                                    style={{
                                      display:
                                        stage.FLD_IsCritical === "Yes"
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <MdWarning className="text-warning" />
                                  </span>
                                </div>
                              }
                            >
                              <div className="stage-card">
                                {(stage?.fld_status === "Open" ||
                                  stage.fld_status === "") && (
                                  <div className="accepte-box">
                                    {stage.FLD_Hold_Time !== "00:00:00" &&
                                      countdownTime > 0 && (
                                        <>
                                          <p className="fs-6">
                                            <IoIosWarning className="text-warning" />{" "}
                                            CAUTION: TIMING CRITICAL{" "}
                                            <IoIosWarning className="text-warning" />
                                          </p>
                                          <span>
                                            This batch of lighters must not move
                                            to the next stage until the
                                            remaining time on the 6-hour
                                            timestamp expires.
                                          </span>
                                        </>
                                      )}

                                    {stage.fld_status === "" && (
                                      <>
                                        <span>
                                          Access is restricted until all the
                                          above stages are completed and
                                          accepted.
                                        </span>
                                      </>
                                    )}
                                    {stage.fld_status === "Open" &&
                                      login[0].FLD_Access_Dept.includes(
                                        stage.Fld_Department_Id
                                      ) && (
                                        <>
                                          {countdownTime <= 0 && (
                                            <span>
                                              Are you sure you want to accept
                                              the batch into the{" "}
                                              {stage.fld_pending_with} stage
                                              now?
                                            </span>
                                          )}
                                          <span
                                            className="default-btn"
                                            onClick={() => {
                                              let criticalStatus;
                                              if (countdownTime > 0) {
                                                criticalStatus = "Yes";
                                              } else {
                                                criticalStatus = "No";
                                              }
                                              UpdateJobCardApprovalHistory(
                                                job.FLD_Job_Card_Id,
                                                stage.FLD_Job_Card_Approval_Id,
                                                criticalStatus
                                              );
                                            }}
                                          >
                                            Accept
                                          </span>
                                        </>
                                      )}
                                  </div>
                                )}
                                <span className="top-text">
                                  Enter the details of batch of lighters
                                  processed at {stage.fld_pending_with},
                                  including deadstock & repairable quantity.
                                </span>
                                <div className="stage-input">
                                  <div className="box">
                                    <span>Quantity In :</span>
                                    <p>{stage.FLD_Item_Qty_In}</p>
                                  </div>
                                  <div className="box">
                                    <span>Dead Stock</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={deadstock}
                                      onChange={(e) =>
                                        setDeadStock(e.target.value)
                                      }
                                    />
                                  </div>
                                  <div className="box">
                                    <span>Repairable</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={repairable}
                                      onChange={(e) =>
                                        setRepairable(e.target.value)
                                      }
                                    />
                                  </div>
                                  {/* <div className="box border-0">
                                    <span>Quantity Out</span>
                                    <Input
                                      disabled={
                                        !login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                      }
                                      allowClear
                                      className="input-box"
                                      value={qtyOut}
                                      onChange={(e) =>
                                        setQtyOut(e.target.value)
                                      }
                                    />
                                  </div> */}
                                  <div
                                    className="mt-3 text-end"
                                    style={{
                                      display:
                                        login[0].FLD_Access_Dept.includes(
                                          stage.Fld_Department_Id
                                        )
                                          ? "block"
                                          : "none",
                                    }}
                                  >
                                    <span
                                      className="default-btn"
                                      onClick={() =>
                                        UpdateJobCardStage(
                                          job.FLD_Job_Card_Id,
                                          stage.Fld_Department_Id
                                        )
                                      }
                                    >
                                      Save
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Panel>
                          );
                        })}
                      </Collapse>
                    </Panel>
                  );
                })}
              </Collapse>
            </div>
            {!filteredCardData.length && (
              <p className="text-center">No job card available at this stage</p>
            )}
            {/* <div className="tray-shifting-floting-btn">
              <span
                className="default-btn fs-5 w-100 d-block"
                onClick={showDrawer}
              >
                Create Lot
              </span>
            </div> */}
          </div>
        </div>
      </div>
      <Modal
        title="Are you sure?"
        centered
        className="stage-modal"
        open={modal2Open}
        onOk={() => setModal2Open(false)}
        onCancel={() => setModal2Open(false)}
      >
        <p>Do you want to save the batch details and move to the next stage?</p>
      </Modal>
      <Drawer
        title="Heading"
        onClose={onClose}
        open={open}
        placement="bottom"
        className="tray-shifting-drawer job-card-page supervisor-filter-drawer tray-shifting-page"
      >
        <div className="job-card-box h-auto mb-3">
          <div className="box">
            <h4 className="text-dark pb-1">Selected Job Cards</h4>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Boxes : </span>
              1000
            </p>
            <p className="w-50">
              <span>Cartons : </span>
              100
            </p>
          </div>
        </div>
        <div className="job-card-box h-auto mb-3 position-relative">
          <div className="box pb-3">
            <div className="d-flex align-items-center">
              <img src={NoImg} className="img-fluid" />
              <p className="card-number">SHIW-031224-RED01</p>
            </div>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Total Quantity : </span>
              10,000
            </p>
            <p className="w-50">
              <span>Current Quantity : </span>
              10,000
            </p>
            <div className="tray-checkbox">
              <IoClose />
            </div>
          </div>
        </div>
        <div className="job-card-box h-auto mb-3 position-relative">
          <div className="box pb-3">
            <div className="d-flex align-items-center">
              <img src={NoImg} className="img-fluid" />
              <p className="card-number">SHIW-031224-RED01</p>
            </div>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Total Quantity : </span>
              10,000
            </p>
            <p className="w-50">
              <span>Current Quantity : </span>
              10,000
            </p>
            <div className="tray-checkbox">
              <IoClose />
            </div>
          </div>
        </div>
        <div className="job-card-box h-auto mb-3 position-relative">
          <div className="box pb-3">
            <div className="d-flex align-items-center">
              <img src={NoImg} className="img-fluid" />
              <p className="card-number">SHIW-031224-RED01</p>
            </div>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Total Quantity : </span>
              10,000
            </p>
            <p className="w-50">
              <span>Current Quantity : </span>
              10,000
            </p>
            <div className="tray-checkbox">
              <IoClose />
            </div>
          </div>
        </div>
        <div className="job-card-box h-auto mb-3 position-relative">
          <div className="box pb-3">
            <div className="d-flex align-items-center">
              <img src={NoImg} className="img-fluid" />
              <p className="card-number">SHIW-031224-RED01</p>
            </div>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Total Quantity : </span>
              10,000
            </p>
            <p className="w-50">
              <span>Current Quantity : </span>
              10,000
            </p>
            <div className="tray-checkbox">
              <IoClose />
            </div>
          </div>
        </div>
        <div className="job-card-box h-auto mb-3 position-relative">
          <div className="box pb-3">
            <div className="d-flex align-items-center">
              <img src={NoImg} className="img-fluid" />
              <p className="card-number">SHIW-031224-RED01</p>
            </div>
          </div>
          <div className="box content justify-content-between">
            <p className="w-50">
              <span>Total Quantity : </span>
              10,000
            </p>
            <p className="w-50">
              <span>Current Quantity : </span>
              10,000
            </p>
            <div className="tray-checkbox">
              <IoClose />
            </div>
          </div>
        </div>
        {/* <div className="tray-shifting-floting-btn bg-white">
          <span className="default-btn fs-5 w-100 d-block" onClick={showDrawer}>
            Create Lot
          </span>
        </div> */}
      </Drawer>
    </>
  );
}
