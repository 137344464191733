import { DatePicker, Table } from "antd";
import { Nav, Tab } from "react-bootstrap";
import React, { useState } from "react";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
export default function WarehouseItemStock() {
  const [customRange, setCustomRange] = useState(false);
  const [activeTab, setActiveTab] = useState("store")
  const handleChange = (date, dateArray) => {
    if (dateArray.length == 2) {
      console.log(dateArray);
    }
  };

  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };

  // Same cols for store and warehouse
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Item Name",
      dataIndex: "itemName",
      key: "itemName",
    },
    // {
    //   title: "Previous Stock",
    //   dataIndex: "previousStock",
    //   key: "previousStock",
    // },
    // {
    //   title: "Stock In",
    //   dataIndex: "stockIn",
    //   key: "stockIn",
    // },
    // {
    //   title: "Stock Out",
    //   dataIndex: "stockOut",
    //   key: "stockOut",
    // },
    {
      title: "Total Stock",
      dataIndex: "totalStock",
      key: "totalStock",
    },
  ];

  // Same row data for store and warehouse statement
  const data = [
    {
      key: "1",
      serial: 1,
      itemName: "White Electric Tray",
      // previousStock: 4,
      // stockIn: 0,
      // stockOut: 0,
      totalStock: 4,
    },
    {
      key: "2",
      serial: 2,
      itemName: "Inner Ashley",
      // previousStock: 24,
      // stockIn: 0,
      // stockOut: 0,
      totalStock: 24,
    },
    {
      key: "3",
      serial: 3,
      itemName: "Inner Triper",
      // previousStock: 0,
      // stockIn: 0,
      // stockOut: 0,
      totalStock: 0,
    },
  ];

  const handleTabSelect = (tab) => {
    setActiveTab(tab)
  }
  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-0">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border job-card-list-table">
              <div className="card-heading">
                <h4> {activeTab == "store" ? "Store Stock Statement" : "Warehouse Stock Statement"}</h4>
              </div>
              <div className="tab-filter">
                <Tab.Container defaultActiveKey="store" onSelect={handleTabSelect}>
                  <div className=" mb-4 w-100 flex-wrap gap-4 d-flex justify-content-between align-items-end">
                    <Nav variant="pills" className="flex-row m-0 w-auto ml-auto">
                      <Nav.Item>
                        <Nav.Link eventKey="store">Store</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="warehouse">Warehouse</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <div>
                      <button type="button" className="default-btn border-0 ms-auto">
                        Download CSV
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-lg-between align-items-lg-center mb-3">
                    <div className=" mb-3 mb-lg-0">
                      <h5 className="text-dark mb-0">Filter Data by Date</h5>
                      <p className="text-black mb-0">Select Date Range</p>
                    </div>
                    {/* <div className="tab-filter ps-0">
                      <Tab.Container
                        id="left-tabs-example"
                        defaultActiveKey="first">
                        <Nav variant="pills" className="flex-row">
                          <Nav.Item>
                            <Nav.Link
                              eventKey="first"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("today");
                              }}>
                              Today
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="second"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("tilldate");
                              }}>
                              Till Date
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="third"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("yesterday");
                              }}>
                              Yesterday
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="forth"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("last7days");
                              }}>
                              Last 7 days
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="fifth"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("last30days");
                              }}>
                              Last 30 days
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="sixth"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("thisweek");
                              }}>
                              This Week
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="seventh"
                              onClick={() => {
                                setCustomRange(false);
                                // getCustomerDetails("thismonth");
                              }}>
                              This Month
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link
                              eventKey="eighth"
                              onClick={() => {
                                setCustomRange(true);
                              }}>
                              Custom Date Range
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Tab.Container>
                    </div> */}
                  </div>
                  {/* {customRange && (
                    <div className="text-end mb-3">
                      <RangePicker
                        // format={DATE_FORMAT}
                        onChange={handleChange}
                      />
                    </div>
                  )} */}
                  <Tab.Content>
                    <Tab.Pane eventKey="store">
                      <Table
                        bordered
                        className="operation-expense-table-size"
                        columns={columns}
                        dataSource={data}
                      />
                    </Tab.Pane>
                    <Tab.Pane eventKey="warehouse">
                      <Table
                        bordered
                        className="operation-expense-table-size"
                        columns={columns}
                        dataSource={data}
                      />
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
