import React from "react";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ children, allowedRoles = [] }) => {
  const isLoggedIn = localStorage.getItem("LoginDetail");
  const parsedLoginData = JSON.parse(localStorage.getItem("LoginDetail"));
  let userRole;
  if (parsedLoginData) {
    userRole = parsedLoginData[0].FLD_Designation;
  }
  if (!isLoggedIn) {
    // to login
    return <Navigate to="/" replace />;
  }

  if (allowedRoles.length > 0 && !allowedRoles.includes(userRole)) {
    return <Navigate to="/unauthorized" replace />;
  }
  // render component
  return children;
};

export default ProtectedRoute;
