import { React, useState, useEffect } from "react";
import { MDBDataTable, MDBCol, MDBTableBody, MDBTableHead } from "mdbreact";
import { AiOutlineEye } from "react-icons/ai";
import { BsFileEarmarkPdf } from "react-icons/bs";
import moment from "moment";
import PostApiCall from "../../PostApi";
const BottomTable = () => {
  const [tableData, setTableData] = useState([]);
  // useEffect(() => {
  //   PostApiCall.postRequest(
  //     {
  //       whereClause: `where fld_staffid = 1`
  //     },
  //     "GetSalesList"
  //   ).then((resultdes) =>
  //     resultdes.json().then((obj) => {
  //       setTableData(obj.data);
  //       console.log(obj);
  //     })
  //   );
  // }, []);
  const data = {
    columns: [
      {
        label: "Order Number ",
        field: "ordername",
        sort: "asc",
        width: 130,
      },
      {
        label: "Date",
        field: "date",
        sort: "asc",
        width: 50,
      },
      {
        label: "Amount",
        field: "amount",
        sort: "asc",
        width: 50,
      },
      {
        label: "Customer Name",
        field: "name",
        sort: "asc",
        width: 350,
      },
      {
        label: "Prepared By",
        field: "preparedby",
        sort: "asc",
        width: 150,
      },
      {
        label: "Status",
        field: "status",
        sort: "asc",
        width: 100,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 10,
      },
      {
        field: "pdf",
        sort: "asc",
        width: 10,
      },
    ],
    rows: tableData.map((data, i) => {
      return {
        quotationname: data.fld_salesid,
        date: moment(data.fld_actiondate).format("YYYY/MM/DD"),
        amount: data.fld_salesid,
        name: data.fld_companyname,
        preparedby: data.fld_name,
        status: data.fld_staffname,
        action: [<AiOutlineEye />, <BsFileEarmarkPdf />],
      };
    }),
  };
  return (
    <div className="margin-left">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 default-card">
            <h5 className="card-heading">Latest Orders</h5>
            {tableData.length != 0 ? (
              <MDBDataTable
                noBottomColumns={true}
                hover
                data={data}
                entries={10}
                entriesOptions={[2, 10, 15]}
                className="latest-ouotes-table"
              >
                <MDBTableHead columns={data.columns} />
                <MDBTableBody rows={data.rows} />
              </MDBDataTable>
            ) : (
              <div className="mt-4 pt-2 row">
                <div className="col-12 text-center my-3">
                  <h6>Data no found</h6>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default BottomTable;
