import React, { useState, useEffect } from "react";
import "./CustomerManagement.css";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Input, message, Upload, Select, Switch, DatePicker } from "antd";
import Search from "antd/es/input/Search";
import TextArea from "antd/es/input/TextArea";

// For integrate customer form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import moment from "moment";

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
export default function CustomerMasterList() {
  const [customerList, setCustomerList] = useState([]);
  const [editingCustomerId, setEditingCustomerId] = useState(null);
  const [formData, setFormData] = useState({});
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    PostApiCall.postRequest({ whereClause: "" }, "GetCustomerList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setCustomerList(obj.data);
          }
        })
    );
  }, []);

  // Initilize UseForm
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // For edit forms
  const handleEditCustomer = (customerId) => {
    const customerUpdate = customerList.find(
      (customer) => customer.FLD_Customer_Id === customerId
    );
    setFormData({
      customername: customerUpdate.FLD_Customer_Name,
      customeraddress: customerUpdate.FLD_Customer_Address,
      customerpincode: customerUpdate.FLD_Customer_Pincode_ID,
      customergstin: customerUpdate.FLD_Customer_GSTIN,
      customercontactperson: customerUpdate.FLD_Customer_Contact_Person,
      customermobile: customerUpdate.FLD_Customer_Mobile,
      customeremail: customerUpdate.FLD_Customer_Email,
      status: customerUpdate.FLD_Enabled_Flag === "Active",
    });
    setEditingCustomerId(customerId);
  };

  const handleUpdateCustomer = (customerId) => {
    Notiflix.Loading.dots("");
    PostApiCall.postRequest(
      {
        customerid: customerId,
        customername: formData.customername,
        customeraddress: formData.customeraddress,
        customercity: formData.customercity,
        customerstate: formData.customerstate,
        customerpincode: formData.customerpincode,
        customergstin: formData.customergstin,
        customercontactperson: formData.customercontactperson,
        customermobile: formData.customermobile,
        customeremail: formData.customeremail,
        status: formData.status ? "Active" : "Inactive",
      },
      "AddCustomer"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status === 200 || results.status === 201) {
          Notiflix.Notify.success("Customer updated successfully.");
          window.location.reload();
        } else {
          Notiflix.Notify.failure("Customer update failed!");
        }
      })
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // For edit and update status
  const handleToggleChange = (checked) => {
    setFormData((prevData) => ({
      ...prevData,
      status: checked,
    }));
  };

  const onSearch = (value) => {
    setSearchValue(value);
  };

  const filteredCustomerList = customerList.filter(
    (customer) =>
      // customer.FLD_Customer_Name.toLowerCase().includes(searchValue.toLowerCase())
      (customer.FLD_Customer_Name &&
        customer.FLD_Customer_Name.toLowerCase().includes(
          searchValue.toLowerCase()
        )) ||
      (customer.FLD_Customer_Address &&
        customer.FLD_Customer_Address.toLowerCase().includes(
          searchValue.toLowerCase()
        )) ||
      (customer.FLD_Customer_Pincode_ID &&
        customer.FLD_Customer_Pincode_ID.toString().includes(searchValue)) ||
      (customer.FLD_Customer_GSTIN &&
        customer.FLD_Customer_GSTIN.toLowerCase().includes(
          searchValue.toLowerCase()
        )) ||
      (customer.FLD_Customer_Contact_Person &&
        customer.FLD_Customer_Contact_Person.toLowerCase().includes(
          searchValue.toLowerCase()
        )) ||
      (customer.FLD_Customer_Mobile &&
        customer.FLD_Customer_Mobile.toString().includes(searchValue)) ||
      (customer.FLD_Enabled_Flag &&
        customer.FLD_Enabled_Flag.toLowerCase().includes(
          searchValue.toLowerCase()
        ))
  );

  const handleDeleteCustomer = (customerId) => {
    confirmAlert({
      title: "Confirm to Delete",
      message: "Are you sure you want to delete this customer?",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            Notiflix.Loading.dots("");
            try {
              const results = await PostApiCall.postRequest(
                {
                  customerid: `${customerId}`,
                  status: "Deleted",
                },
                "DeleteCustomer"
              );
              if (results.status === 200 || results.status === 201) {
                Notiflix.Notify.success("Customer successfully deleted.");
                setCustomerList((prevList) =>
                  prevList.filter(
                    (customer) => customer.FLD_Customer_Id !== customerId
                  )
                );
              } else {
                Notiflix.Notify.failure("Failed to delete the customer.");
              }
            } catch (error) {
              Notiflix.Notify.failure("An error occurred: " + error.message);
            } finally {
              Notiflix.Loading.remove();
            }
          },
        },
        {
          label: "No",
        },
      ],
    });
  };
  const [age, setAge] = React.useState("");

  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, (url) => {
        setLoading(false);
        setImageUrl(url);
      });
    }
  };
  const label = { inputProps: { "aria-label": "Switch demo" } };

  return (
    <div className="row pt-0 mt-0 mb-4 px-0">
      <div className="col-12 px-0">
        <div className="default-card default-card-border mt-2">
          <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
            <h4>List of Customers ({filteredCustomerList.length} Nos.)</h4>
            <div className="search-box w-50">
              <Search
                placeholder="Search Customer    "
                allowClear
                className="w-100"
                onSearch={onSearch}
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
              />
            </div>
          </div>

          {filteredCustomerList?.length > 0 ? (
            filteredCustomerList?.map((customer) => (
              <div className="spare-card">
                <div className="row">
                  <div className="col-12 pb-3" key={customer.FLD_Customer_Id}>
                    <Input
                      size="large"
                      placeholder="Customer Name"
                      allowClear
                      className="input-box"
                      name="customername"
                      defaultValue={customer.FLD_Customer_Name}
                      disabled={editingCustomerId !== customer.FLD_Customer_Id}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-lg-4 pb-3">
                    <TextArea
                      className="input-box input-box-textarea"
                      placeholder="Address"
                      allowClear
                      name="customeraddress"
                      defaultValue={customer.FLD_Customer_Address}
                      disabled={editingCustomerId !== customer.FLD_Customer_Id}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-8">
                    <div className="row">
                      <div className="col-lg-6 pb-3">
                        <Input
                          size="large"
                          placeholder="Pincode"
                          allowClear
                          className="input-box"
                          name="customerpincode"
                          defaultValue={customer.FLD_Customer_Pincode_ID}
                          disabled={
                            editingCustomerId !== customer.FLD_Customer_Id
                          }
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-lg-6 pb-3">
                        <Input
                          size="large"
                          placeholder="GSTIN"
                          allowClear
                          className="input-box"
                          name="customergstin"
                          defaultValue={customer.FLD_Customer_GSTIN}
                          disabled={
                            editingCustomerId !== customer.FLD_Customer_Id
                          }
                          onChange={handleInputChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 pb-3">
                    <Input
                      size="large"
                      placeholder="Contact Person"
                      allowClear
                      className="input-box"
                      name="customercontactperson"
                      defaultValue={customer.FLD_Customer_Contact_Person}
                      disabled={editingCustomerId !== customer.FLD_Customer_Id}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-4 pb-3">
                    <Input
                      size="large"
                      placeholder="Mobile Number"
                      allowClear
                      className="input-box"
                      name="customermobile"
                      defaultValue={customer.FLD_Customer_Mobile}
                      disabled={editingCustomerId !== customer.FLD_Customer_Id}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div className="col-lg-4 pb-3">
                    <Input
                      size="large"
                      placeholder="Email ID"
                      allowClear
                      className="input-box"
                      name="customeremail"
                      defaultValue={customer.FLD_Customer_Email}
                      disabled={editingCustomerId !== customer.FLD_Customer_Id}
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="col-12">
                    <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                      <div className="d-flex align-items-center gap-2">
                        <p className="mb-0">Inactive</p>
                        <Switch
                          checked={
                            editingCustomerId === customer.FLD_Customer_Id
                              ? formData.status
                              : customer.FLD_Enabled_Flag === "Active"
                          }
                          disabled={
                            editingCustomerId !== customer.FLD_Customer_Id
                          }
                          onChange={handleToggleChange}
                        />
                        <p className="mb-0">Active</p>
                        <p className="mb-0 ms-3">
                          Last Edited -
                          {customer.FLD_Updated_on && (
                            <>
                              {moment(customer.FLD_Updated_on).format(
                                "DD-MM-YYYY"
                              )}
                            </>
                          )}
                        </p>
                      </div>

                      <div className="d-flex gap-3">
                        <Button
                          className="default-btn border-0 mt-3"
                          onClick={() =>
                            handleDeleteCustomer(customer.FLD_Customer_Id)
                          }>
                          Delete Customer
                        </Button>
                        <Button
                          className="default-btn border-0 mt-3"
                          onClick={() =>
                            editingCustomerId === customer.FLD_Customer_Id
                              ? handleUpdateCustomer(customer.FLD_Customer_Id)
                              : handleEditCustomer(customer.FLD_Customer_Id)
                          }>
                          {editingCustomerId === customer.FLD_Customer_Id
                            ? "Update Customer"
                            : "Edit Customer"}
                        </Button>

                        {editingCustomerId === customer.FLD_Customer_Id && (
                          <Button
                            className="ml-2 default-btn border-0 mt-3"
                            onClick={() => setEditingCustomerId(null)}>
                            Cancel
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No Data Available</p>
          )}
        </div>
      </div>
    </div>
  );
}
