import { Dropdown, Space, Table, Tag } from "antd";
import Search from "antd/es/input/Search";
import { FaEye } from "react-icons/fa";
import { DownOutlined } from "@ant-design/icons";
export default function SpareDispatchGrid() {
  // This table is for storekeepers 
  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com">
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      ),
    },
  ];
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
      key: "requestedOn",
    },
    {
      title: "Requested By",
      dataIndex: "requestedBy",
      key: "requestedBy",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      render: (_, { status }) => (
        <>
          {status.map((status) => {
            let color = status.length > 5 ? "yellow" : "green";
            if (status === "Dispatched") {
              color = "green";
            }
            return (
              <Tag color={color} key={status}>
                {status.toUpperCase()}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button type="button" className="default-btn border-0">
            View <FaEye />
          </button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      serial: 1,
      orderNo: "12345",
      itemCount: 2,
      requestedOn: "15-11-2024",
      requestedBy: "Himanshu",
      status: ["Dispatched"],
    },
    {
      key: "2",
      serial: 2,
      orderNo: "12345",
      itemCount: 5,
      requestedOn: "11-11-2024",
      requestedBy: "Pragya",
      status: ["Pending"],
    },
    {
      key: "3",
      serial: 3,
      orderNo: "12345",
      itemCount: 8,
      requestedOn: "10-11-2024",
      requestedBy: "Himanshu",
      status: ["Dispatched"],
    },
  ];
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  return (
    <>
      <div className="margin-left blank-section">
        <div className="container-fluid pt-4 pb-2">
          <div className="row pt-4 mt-4 mb-4 px-0">
            <div className="col-12 px-0">
              <div className="default-card default-card-border">
                <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                  <h4>List of Spares Request (25 Nos.)</h4>
                  <div className="d-flex gap-4 align-items-end justify-content-end w-50">
                    <div className="search-box w-75">
                      <Search
                        placeholder="Search Spares Request"
                        allowClear
                        onSearch={onSearch}
                        className="w-100"
                      />
                    </div>
                    <div>
                      <Dropdown
                        className="default-btn fs-6"
                        menu={{
                          items,
                        }}
                        placement="bottomRight"
                        arrow>
                        <Space>
                          Sort by <DownOutlined />
                        </Space>
                      </Dropdown>
                    </div>
                  </div>
                </div>
                <div className="job-card-list-table">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={data}
                    className="operation-expense-table-size"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
