import React, { useState } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { TableSection } from "./Table";

export const CalendarSection = () => {
//   const [value, onChange] = useState(new Date());

  return (
    <div className="margin-left">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col-12 px-0">
            <div className="default-card">
              <div className="d-flex justify-content-between border-bottom border-2">
                <h5 className="card-heading border-0 mb-0">Job Cards Status</h5>
                <h5 className="card-heading border-0 mb-0">
                  Today's Job Cards - 123
                </h5>
              </div>

              <div className="product-management-cards-box mt-3">
                <div className="product-management-card">
                  <span>Tray Shifting</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Gas Filling</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Flame Test 1</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Flame Test 2</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Parts Assembly</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Quality Check</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Final Tray Shifting</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Packaging</span>
                  <p>123</p>
                </div>
                <div className="product-management-card">
                  <span>Dispatch</span>
                  <p>123</p>
                </div>
              </div>

              {/* <TableSection /> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
