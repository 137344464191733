import React from "react";
import BottomTable from "../BottomTable/BottomTable";
import { CalendarSection } from "../Calendar/Calender";
import { Charts } from "../Chart/Chart";
import { TopHeader } from "../TopHeader/TopHeader";
import SupervisorDashboard from "../SupervisorDashboard/SupervisorDashboard";

export const Dashboard = () => {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));

  return (
    <>
      {login[0]?.FLD_User_Type !== 3 ? (
        <SupervisorDashboard />
      ) : (
        <>
          <TopHeader />
          <CalendarSection />
          <Charts />
          <BottomTable />
        </>
      )}
    </>
  );
};
