import { BsBox2Fill } from "react-icons/bs";
import { FaBoxes, FaCalendarAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export default function SpareRequestList() {
  const location = useLocation();
  const requestedSpare = location.state;
  const formstate = true;
  return (
    <div className="margin-left blank-section job-card-page supervisor-dashboard spare-card-list-page">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-lg-center gap-3 flex-column flex-lg-row">
                <h4 className="mb-0">Spare Request List</h4>
                <div className="mb-2 mb-lg-0">
                  <Link
                    to="/spares-request-form"
                    state={formstate}
                    className="default-btn">
                    Request a Spare
                  </Link>
                </div>
              </div>
              <div className="job-card-main-box spare-card-list">
                {requestedSpare?.map((spares, index) => {
                  return (
                    <div key={index} className="job-card-box">
                      <div className="box">
                        <BsBox2Fill />
                        <p>{spares.items_name}</p>
                      </div>
                      <div className="box">
                        <FaBoxes />
                        <p>{spares.items_total_count}</p>
                      </div>
                      <div className="box">
                        <FaCalendarAlt />
                        <p>{spares.FLD_Spares_Stock_Request_Date}</p>
                      </div>
                      <div className="status-box">
                        <p>{spares.FLD_Enabled_Flag}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
