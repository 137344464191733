import React from "react";
import { Modal } from "react-bootstrap";
import { LuLogOut } from "react-icons/lu";
export default function LogoutModal({ show, onHide, onLogout }) {
  return (
    <>
      <Modal centered show={show} onHide={onHide} className="logout-modal">
        <Modal.Header closeButton className="border-0 pb-0"></Modal.Header>
        <Modal.Body className="pt-0">
          <div className="login-box pb-0">
            <div className="section-title mb-0 mt-0 text-center">
              <div className="login-modal-icon fs-1">
                <LuLogOut />
              </div>
              <h4 className="text-center text-dark mb-0">Confirm Logout</h4>
              <p className="text-dark">
                You will be redirected to the login page.
              </p>
            </div>

            <div className="text-center">
              <button className="default-btn border-0 me-3" onClick={onLogout}>
                Yes
              </button>
              <button className="default-btn border-0" onClick={onHide}>
                Cancel
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
