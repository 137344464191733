import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
export default function ColumnChart() {
    // constructor(props) {
    //     super(props);

    //     this.state = {

    //         series: [{
    //             name: 'Net Profit',
    //             data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
    //         }, {
    //             name: 'Revenue',
    //             data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
    //         }, {
    //             name: 'Free Cash Flow',
    //             data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
    //         }],
    //         options: {
    //             chart: {
    //                 type: 'bar',
    //                 height: 350
    //             },
    //             plotOptions: {
    //                 bar: {
    //                     horizontal: false,
    //                     columnWidth: '55%',
    //                     endingShape: 'rounded'
    //                 },
    //             },
    //             dataLabels: {
    //                 enabled: false
    //             },
    //             stroke: {
    //                 show: true,
    //                 width: 2,
    //                 colors: ['transparent']
    //             },
    //             xaxis: {
    //                 categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
    //             },
    //             yaxis: {
    //                 title: {
    //                     text: '$ (thousands)'
    //                 }
    //             },
    //             fill: {
    //                 opacity: 1
    //             },
    //             tooltip: {
    //                 y: {
    //                     formatter: function (val) {
    //                         return "$ " + val + " thousands"
    //                     }
    //                 }
    //             }
    //         },


    //     };
    // }

    const [chartDataCustomer, setChartDataCustomer] = useState({
        options: {
          chart: {
            height: 280,
            // width: '100%',
            type: "bar",
            stacked: !0,
            toolbar: {
              show: 0,
            },
            zoom: {
              enabled: !0,
            },
          },
          plotOptions: {
            bar: {
              horizontal: 0,
              columnWidth: "50%",
              endingShape: "rounded",
            },
          },
          dataLabels: {
            enabled: 0,
          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          legend: {
            position: "bottom",
            show: 0,
          },
          colors: ["var(--color-theme)", "#9fb87382"],
          fill: {
            colors: ["var(--color-theme)", "#9fb87382"],
          },
          stroke: {
            curve: "smooth",
          },
          // responsive: {
          //     breakpoint: undefined,
          //     options: {},
          // }
          responsive: [
            {
              breakpoint: 1000,
              yaxis: {
                categories: [
                  "Jan",
                  "Feb",
                  "März",
                  "Apr",
                  "Mai",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Okt",
                  "Nov",
                  "Dez",
                ],
              },
              options: {
                plotOptions: {
                  bar: {
                    horizontal: true,
                  },
                },
              },
            },
          ],
        },
        series: [
          {
            name: "series1",
            data: [31, 40, 28, 51, 42, 109, 100, 52, 24, 18, 36, 48],
          },
          {
            name: "series2",
            data: [11, 32, 45, 32, 34, 52, 41, 52, 24, 18, 36, 48],
          },
        ],
      });

    
        return (


            // <div id="chart">
            //     <ReactApexChart options={this.state.options} series={this.state.series} type="bar" height={350} />
            // </div>

            <ReactApexChart
            options={chartDataCustomer.options}
            series={chartDataCustomer.series}
            type="bar"
            height={320}
          />
        );
    
}
