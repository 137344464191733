import React, { useState, useContext, useEffect } from "react";
import { DatePicker, Input, message, Switch, Upload } from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

// For integrate brands form using react UseForm
import { useForm } from "react-hook-form";
import PostApiCall from "../../PostApi";
import Notiflix from "notiflix";
import BrandList from "./BrandMasterList";
import { store } from "../../context/Store";

// For image upload preview image
const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

export default function BrandMaster() {
  useEffect(() => {
    getBrands();
  }, [])
  const [status, setStatus] = useState(false);

  // For upload images
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [randomNumber, setRandomNumber] = useState(
    Math.floor(Math.floor(100000 + Math.random() * 900000))
  );
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    reset,
    watch,
    formState: { errors },
  } = useForm();

  const getBrands = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetBrandList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setBrandList(obj.data);
          }
        })
    );
  }

  // console.log(brandUniqId, "storeid");
  const onSubmit = (data) => {
    Notiflix.Loading.dots("Please wait!");
    PostApiCall.postRequest(
      {
        brandUniqId: null,
        brandname: data.brandname,
        brandlogo: imageUrl,
        status: status ? "Active" : "Inactive",
      },
      "AddBrand"
    ).then((results) =>
      results.json().then((obj) => {
        Notiflix.Loading.remove();
        if (results.status == 200 || results.status == 201) {
          Notiflix.Notify.success("Brand registered sucessfully.");
          reset({
            brandname: ""
          })
          setImageUrl("");
          setStatus(false) // checkbox status
          getBrands(); // get brand list 
        } else {
          Notiflix.Notify.failure("Brand registration failed!");
        }
      })
    );
  };

  const handleChange = (field) => {
    return (e) => {
      clearErrors(field);
      setValue(field, e.target.value);
    };
  };

  // For status
  const handleStatusChange = (checked) => {
    setStatus(checked);
  };

  // Handle Image on server
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload Brand Logo
      </div>
    </button>
  );

  const onaction = async (dat) => {
    setLoading(true);
    const formData = new FormData();
    formData.append(
      "filename",
      randomNumber + "-" + dat.file.name.replace(/ /g, "-")
    );
    formData.append("foldername", "brandImages");
    formData.append("file", dat.file);

    try {
      const response = await fetch(
        "https://shivaayintl.com/shivaay-api/AddImage",
        {
          method: "POST",
          body: formData,
        }
      );
      const imgResponse = await response.json();
      setImageUrl(imgResponse.image);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="margin-left">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-0 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border">
              <div className="card-heading d-flex justify-content-between align-items-center">
                <h4>Brand</h4>
                <p className="mb-0">
                  Fields marked with <span className="text-danger">*</span> are
                  mandatory.
                </p>
              </div>
              <div className="row">
                <div className="col-lg-2 pb-3">
                  <Upload
                    name="avatar"
                    listType="picture-card"
                    className="avatar-uploader w-100"
                    showUploadList={false}
                    customRequest={onaction}
                    beforeUpload={beforeUpload}
                  >
                    {imageUrl && (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{
                          width: "100%",
                        }}
                      />
                    )}
                    {imageUrl === "" && uploadButton}
                  </Upload>
                </div>
                <div className="col-lg-4 pb-3">
                  <label htmlFor="brandname">Brand Name<span className="text-danger">*</span></label>
                  <Input
                    size="large"
                    // placeholder="Brand Name"
                    allowClear
                    value={watch("brandname") || ""}
                    className="input-box"
                    id="brandname"
                    {...register("brandname", {
                      required: "brandname is required",
                    })}
                    onChange={handleChange("brandname")}
                  />
                  {errors.brandname && (
                    <p className="text-danger">{errors.brandname.message}</p>
                  )}
                </div>
                <div className="col-12">
                  <div className="btn-border mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center gap-2">
                      <p className="mb-0">Inactive</p>
                      <Switch onChange={handleStatusChange} />
                      <p className="mb-0">Active</p>
                    </div>

                    <div>
                      <Button
                        className="default-btn border-0 mt-3"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Save Brand
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Show Brand List */}
        <BrandList brandList={brandList} getBrands={getBrands} />
      </div>
    </div>
  );
}
