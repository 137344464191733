import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [brandUniqId, setBrandId] = useState([]);
  const [brandList, setBrandList] = useState([]);
  const [loginDetails, setLoginDetails] = useState([]);
  return (
    <store.Provider
      value={{
        brandUniqId,
        setBrandId,
        brandList,
        setBrandList,
        loginDetails,
        setLoginDetails,
      }}
    >
      {children}
    </store.Provider>
  );
};

export default Provider;
