import "./App.css";
import MinimalLayout from "./component/Layouts/MinimalLayout";
import DefaultLayout from "./component/Layouts/DefaultLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Dashboard } from "./component/Pages/Dashboard";
import ProtectedRoutes from "./Utils/ProtectedRoutes";
import Login from "./component/LoginPage/Login";
// import ChangePassword from "./component/ChangePassword/ChangePasswordModal";
import WarehouseItemStock from "./component/StockManagement/WarehouseItemStock";
import SparesRequestForm from "./component/StockManagement/SparesRequestForm";
import ItemSparesStock from "./component/StockManagement/ItemSparesStock";
import ItemSparesMaster from "./component/ItemManagement/ItemSparesMaster";
import EmployeeMasterList from "./pages/EmployeeManagement/EmployeeMasterList";
import ItemMaster from "./component/ItemManagement/ItemMaster";
import CustomerMaster from "./component/CustomerManagement/CustomerMaster";
import OrderMaster from "./component/OrderManagement/OrderMaster";
import NotificationMaster from "./component/NotificationManagement/NotificationMaster";
import BrandMaster from "./component/BrandMaster/BrandMaster";
import DepartmentMaster from "./component/DepartmentMaster/DepartmentMaster";
import EquipmentMaster from "./component/EquipmentMaster/EquipmentMaster";
import StoreMaster from "./component/StoreMaster/StoreMaster";
import WarehouseMaster from "./component/WarehouseMaster/WarehouseMaster";
import StaffMaster from "./component/StaffManagement/StaffMaster";
import JobMovementRecord from "./component/ProductionManagement/JobMovementRecord";
import CreateJobCard from "./component/ProductionManagement/CreateJobCard";
import JobCardList from "./component/ProductionManagement/JobCardList";
import StageDeskPage from "./component/ProductionManagement/StageDeskPage";
// import AuthorizedRoute from "./Utils/AuthorizedRoute";
import Provider from "./context/Store";
import CompanyMasterList from "./pages/MasterManagement/CompanyMasterList";
import SpareDispatchGrid from "./component/StockManagement/SpareDispatchGrid";
import { JobCardAccordion } from "./component/ProductionManagement/JobCardAccordion";
import SupervisorDashboard from "./component/SupervisorDashboard/SupervisorDashboard";
import SpareRequestList from "./component/StockManagement/SpareRequestList";
import PageNotFound from "./component/ErrorPage/PageNotFound";
import UnauthorizedAccess from "./component/ErrorPage/UnauthorizedAccess";
import TrayShifting from "./component/ProductionManagement/TrayShifting";

function App() {
  return (
    <>
      <Provider>
        <BrowserRouter>
          <Routes>
            {/* //////////////////// Routes without nav and footer /////////// */}
            <Route
              exact
              path="/"
              element={
                <MinimalLayout>
                  <Login />
                </MinimalLayout>
              }
            />

            {/*///////////////////////// Routes with nav and footer - protected routes ///////////////*/}
            <Route
              path="/dashboard"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <Dashboard />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />

            {/* <Route
              exact
              path="/change-password"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <ChangePassword />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            /> */}
            <Route
              exact
              path="/employee-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <EmployeeMasterList />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/company-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <CompanyMasterList />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/item-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <ItemMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/customer-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <CustomerMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/order-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <OrderMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/notification-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <NotificationMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/brand-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <BrandMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/department-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <DepartmentMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/equipment-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <EquipmentMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/store-master"
              element={
                <DefaultLayout>
                  <StoreMaster />
                </DefaultLayout>
              }
            />
            <Route
              exact
              path="/warehouse-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <WarehouseMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/staff-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <StaffMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/item-spares-stock"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <ItemSparesStock />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/item-stock"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <WarehouseItemStock />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/spares-request-form"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <SparesRequestForm />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/spare-dispatch-grid"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <SpareDispatchGrid />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/create-job-card"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <CreateJobCard />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/job-movement-record"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <JobMovementRecord />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            {/* <Route
              exact
              path="/job-card-list"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <JobCardList />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            /> */}
            <Route
              exact
              path="/job-card-accordion"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <JobCardAccordion />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/tray-shifting"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <TrayShifting />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/stage"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <StageDeskPage />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/active-job-card-list"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <JobCardAccordion />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />

            <Route
              exact
              path="/item-spares-master"
              element={
                <ProtectedRoutes allowedRoles={["Admin"]}>
                  <DefaultLayout>
                    <ItemSparesMaster />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/supervisor-dashboard"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <SupervisorDashboard />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            <Route
              exact
              path="/spare-request-list"
              element={
                <ProtectedRoutes>
                  <DefaultLayout>
                    <SpareRequestList />
                  </DefaultLayout>
                </ProtectedRoutes>
              }
            />
            {/* </Route> */}
            <Route
              path="/unauthorized"
              element={
                <DefaultLayout>
                  <UnauthorizedAccess />
                </DefaultLayout>
              }
            />
            <Route
              path="*"
              element={
                <DefaultLayout>
                  <PageNotFound />
                </DefaultLayout>
              }
            />
          </Routes>
        </BrowserRouter>
      </Provider>
    </>
  );
}

export default App;
