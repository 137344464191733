import {
  DatePicker,
  Dropdown,
  Input,
  message,
  Space,
  Switch,
  Table,
  Tag,
  Upload,
} from "antd";
import Search from "antd/es/input/Search";
import { Button } from "react-bootstrap";
import { DownOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import PostApiCall from "../../PostApi";
import { Link, useLocation } from "react-router-dom";
import customParseFormat from "dayjs/plugin/customParseFormat";
import NoImg from "../../assets/img/no-img.jpg";
import { FaEye } from "react-icons/fa";
import Notiflix from "notiflix";
import Offcanvas from "react-bootstrap/Offcanvas";
import { MdDelete } from "react-icons/md";

export default function SparesRequestForm() {
  var login = JSON.parse(localStorage.getItem("LoginDetail"));
  const location = useLocation();
  const formState = location.state;
  const onSearch = (value, _e, info) => console.log(info?.source, value);
  const [showForm, setShowForm] = useState(formState ? true : false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [requestedSpare, setRequestedSpare] = useState([]);
  const [localItems, setLocalItems] = useState([]);
  useEffect(() => {
    const getSpareRequests = () => {
      Notiflix.Loading.dots();
      PostApiCall.postRequest(
        {
          whereClause: `where FLD_Created_By = ${login[0].FLD_User_Id} `,
        },
        "GetSpareRequests"
      ).then((resultdes) => {
        resultdes.json().then((obj) => {
          try {
            const transformedData = obj.data.map((item, index) => ({
              key: index + 1,
              serial: index + 1,
              orderNo: item.FLD_Req_No,
              itemCount: item.items_count,
              requestedOn: item.FLD_Spares_Stock_Request_Date,
              status: item.FLD_Enabled_Flag,
            }));

            setRequestedSpare(transformedData);
          } catch (error) {
            console.log(error);
            Notiflix.Loading.remove();
          }
          Notiflix.Loading.remove();
        });
      });
    };

    getSpareRequests();
  }, []);

  const handleShow = () => {
    const existingItems =
      JSON.parse(localStorage.getItem("requestedItems")) || [];
    setLocalItems(existingItems); // Update state with items
    setShow(true);
  };

  const handleToggleView = (action) => {
    if (action == "Back") {
      setShowForm(false);
    } else if (action == "Form") {
      setShowForm(true);
    }
  };

  const [spares, setSpares] = useState([]);
  useEffect(() => {
    getSpareItems();
  }, []);

  const getSpareItems = () => {
    PostApiCall.postRequest({ whereClause: "" }, "GetSpareItemList").then(
      (results) =>
        results.json().then((obj) => {
          if (results.status === 200 || results.status === 201) {
            setSpares(obj.data);
          }
        })
    );
  };

  const sendSpareRequest = (itemdetails) => {
    const jsonData = Object.values(itemdetails);
    const items = JSON.stringify(jsonData);
    PostApiCall.postRequest(
      {
        orderid: null,
        userid: login[0]?.FLD_User_Id,
        itemdetails: items,
      },
      "RequestSpareItems"
    ).then((results) =>
      results.json().then((obj) => {
        try {
          if (results.status === 200 || results.status === 201) {
            localStorage.removeItem("requestedItems");
            setLocalItems([]);
            Notiflix.Notify.success("Request send succesfully");
          } else {
            Notiflix.Notify.failure("Something went wrong");
          }
        } catch (error) {
          console.log(error);
        }
      })
    );
  };

  const items = [
    {
      key: "1",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.antgroup.com">
          1st menu item
        </a>
      ),
    },
    {
      key: "2",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.aliyun.com">
          2nd menu item
        </a>
      ),
    },
    {
      key: "3",
      label: (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.luohanacademy.com">
          3rd menu item
        </a>
      ),
    },
  ];
  const columns = [
    {
      title: "S No.",
      dataIndex: "serial",
      key: "serial",
    },
    {
      title: "Order No.",
      dataIndex: "orderNo",
      key: "orderNo",
    },
    {
      title: "Item Count",
      dataIndex: "itemCount",
      key: "itemCount",
    },
    {
      title: "Requested On",
      dataIndex: "requestedOn",
      key: "requestedOn",
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      // render: (_, { status }) => (
      //   <>
      //     {status.map((status) => {
      //       let color = status.length > 5 ? "yellow" : "green";
      //       if (status === "Dispatched") {
      //         color = "green";
      //       }
      //       return (
      //         <Tag color={color} key={status}>
      //           {status.toUpperCase()}
      //         </Tag>
      //       );
      //     })}
      //   </>
      // ),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="middle">
          <button type="button" className="default-btn border-0">
            View <FaEye />
          </button>
        </Space>
      ),
    },
  ];
  const data = [
    {
      key: "1",
      serial: 1,
      orderNo: "12345",
      itemCount: 2,
      requestedOn: "15-11-2024",
      status: ["Dispatched"],
    },
    {
      key: "2",
      serial: 2,
      orderNo: "12345",
      itemCount: 5,
      requestedOn: "11-11-2024",
      status: ["Pending"],
    },
    {
      key: "3",
      serial: 3,
      orderNo: "12345",
      itemCount: 8,
      requestedOn: "10-11-2024",
      status: ["Dispatched"],
    },
  ];
  const onChange = (e) => {
    console.log(e);
  };
  dayjs.extend(customParseFormat);
  const { RangePicker } = DatePicker;
  const range = (start, end) => {
    const result = [];
    for (let i = start; i < end; i++) {
      result.push(i);
    }
    return result;
  };
  const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().endOf("day");
  };
  const disabledDateTime = () => ({
    disabledHours: () => range(0, 24).splice(4, 20),
    disabledMinutes: () => range(30, 60),
    disabledSeconds: () => [55, 56],
  });
  const disabledRangeTime = (_, type) => {
    if (type === "start") {
      return {
        disabledHours: () => range(0, 60).splice(4, 20),
        disabledMinutes: () => range(30, 60),
        disabledSeconds: () => [55, 56],
      };
    }
    return {
      disabledHours: () => range(0, 60).splice(20, 4),
      disabledMinutes: () => range(0, 31),
      disabledSeconds: () => [55, 56],
    };
  };
  const handleItemToLocal = (itemid, spareimg, sparename) => {
    // Quantity input value
    const inputElement = document.getElementById(`quantity-${itemid}`);
    const quantity = inputElement ? parseInt(inputElement.value, 10) : 0;
    if (quantity <= 0 || isNaN(quantity)) {
      Notiflix.Notify.failure("Invalid quantity");
      return;
    }

    // existing local data
    const existingItems =
      JSON.parse(localStorage.getItem("requestedItems")) || [];

    // check for spare item in existing data
    const itemIndex = existingItems.findIndex((spare) => spare.Item === itemid);

    if (itemIndex >= 0) {
      // update the count if it exists in localstorage
      existingItems[itemIndex].Quantity += quantity;
    } else {
      // new item to localstorage
      existingItems.push({
        Item: itemid,
        Quantity: quantity,
        Image: spareimg,
        ItemName: sparename,
      });
    }

    // update the localstorage
    localStorage.setItem("requestedItems", JSON.stringify(existingItems));
    inputElement.value = "";

    Notiflix.Notify.success("Item added successfully!");
  };

  const handleRemoveItem = (itemid) => {
    const updatedLocalStorage = localItems.filter(
      (spare) => spare.Item !== itemid
    );
    localStorage.setItem("requestedItems", JSON.stringify(updatedLocalStorage));

    setLocalItems(updatedLocalStorage);
  };

  return (
    <div className="margin-left blank-section">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          {/* spare request form */}
          {showForm ? (
            <>
              <div className="col-12 px-0">
                <div className="default-card default-card-border">
                  <div className="card-heading">
                    <h4>Spares Request</h4>
                  </div>
                  <div className="row">
                    {spares
                      ?.filter((spare) => spare.FLD_Enabled_Flag == "Active")
                      ?.map((spare) => {
                        return (
                          <div
                            key={spare.FLD_Item_Spares_Id}
                            className="col-6 col-lg-2 mb-3">
                            <div className="border spare-request-card">
                              <img
                                src={spare?.FLD_Item_Spare_Image || NoImg}
                                className="img-fluid w-100 mb-3"
                                alt=""
                              />
                              <h6 className="mb-2">
                                {spare.FLD_Item_Spare_Name}
                              </h6>
                              <label
                                htmlFor={`quantity-${spare.FLD_Item_Spares_Id}`}
                                className="mb-1">
                                Quantity
                              </label>
                              <Input
                                id={`quantity-${spare.FLD_Item_Spares_Id}`}
                                size="small"
                                allowClear
                                className="input-box p-1"
                              />
                              <button
                                className="default-btn fs-6 border-0 mt-3 mt-lg-2"
                                onClick={() => {
                                  handleItemToLocal(
                                    spare.FLD_Item_Spares_Id,
                                    spare.FLD_Item_Spare_Image,
                                    spare.FLD_Item_Spare_Name
                                  );
                                }}>
                                Add Item(s)
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    <div className="col-12">
                      <div className="btn-border mt-2 pt-3 text-end align-items-center d-flex justify-content-end">
                        <Button
                          onClick={() => {
                            handleToggleView("Back");
                          }}
                          view
                          className="default-btn border-0 d-none d-lg-inline-block">
                          Go Back
                        </Button>
                        <Link
                          to="/spare-request-list"
                          className="default-btn d-inline-block d-lg-none go-back-btn">
                          Go Back
                        </Link>
                        <Button
                          onClick={() => handleShow()}
                          className="default-btn border-0 ms-3 ms-lg-4">
                          View Added Items
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* side drawer to show added items */}
              <Offcanvas
                placement="end"
                show={show}
                onHide={handleClose}
                className="view-added-spares-modal">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Requested Items</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  {localItems.length === 0 ? (
                    <p>No items added yet!</p>
                  ) : (
                    <ul>
                      {localItems.map((item, index) => (
                        <li
                          key={index}
                          className="d-flex justify-content-between align-items-center mb-3">
                          <div className="d-flex align-items-center">
                            <img
                              src={item.Image || "path/to/default-image.jpg"}
                              alt={item.Name}
                              className="img-fluid"
                              style={{
                                width: "50px",
                                height: "50px",
                                marginRight: "10px",
                              }}
                            />
                            <div>
                              <strong>{item.ItemName}</strong>
                              <br />
                              <span>Quantity: {item.Quantity}</span>
                            </div>
                          </div>
                          <Button
                            variant="danger"
                            size="sm"
                            onClick={() => handleRemoveItem(item.Item)}>
                            <MdDelete />
                          </Button>
                        </li>
                      ))}
                    </ul>
                  )}
                  {localItems.length > 0 && (
                    <div className="mt-4 d-grid sticky-bottom">
                      <Button
                        size="lg"
                        className="border-0 default-btn"
                        onClick={() => sendSpareRequest(localItems)}>
                        Send Request
                      </Button>
                    </div>
                  )}
                </Offcanvas.Body>
              </Offcanvas>
            </>
          ) : (
            // spare request listing
            <div className="col-12 px-0">
              <div className="default-card default-card-border mt-2">
                {/* <div className="card-heading mb-3  d-flex justify-content-between align-items-center">
                  <h4>List of Spares Request</h4>
                  <div className="d-flex gap-4 align-items-end justify-content-end">
                    <div className="search-box w-50 ">
                      <Search
                        placeholder="Search Spares Request"
                        allowClear
                        onSearch={onSearch}
                        className="w-100"
                      />
                    </div> 
                     <div>
                      <Dropdown
                        className="default-btn fs-6"
                        menu={{
                          items,
                        }}
                        placement="bottomRight"
                        arrow
                      >
                        <Space>
                          Sort by <DownOutlined />
                        </Space>
                      </Dropdown>
                    </div> 
                    <div>
                      <button
                        className="default-btn fs-6 border-0"
                        onClick={() => {
                          handleToggleView("Form");
                        }}
                      >
                        Request A Spare
                      </button>
                    </div>
                  </div>
                </div> */}
                <div className="card-heading d-flex justify-content-between align-items-lg-center gap-3 flex-column flex-lg-row">
                  <h4 className="mb-0">Spare Request List</h4>
                  <div className="mb-2 mb-lg-0">
                    <button
                      className="default-btn fs-6 border-0"
                      onClick={() => {
                        handleToggleView("Form");
                      }}>
                      Request A Spare
                    </button>
                  </div>
                </div>
                <div className="job-card-list-table">
                  <Table
                    bordered
                    columns={columns}
                    dataSource={requestedSpare}
                    className="operation-expense-table-size"
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
