import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
export default function LineChart() {
  const [chartData, setChartData] = useState({
    options: {
      chart: {
        height: 280,
        type: "area",
        stacked: !0,
        toolbar: {
          show: 0,
        },
        zoom: {
          enabled: !0,
        },
      },
      plotOptions: {
        bar: {
          horizontal: !0,
          columnWidth: "10%",
          endingShape: "rounded",
        },
      },
      dataLabels: {
        enabled: 0,
      },
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          
        ],
      },
      legend: {
        position: "bottom",
        show: 0,
      },
      colors: ["var(--color-theme)", "#9fb87382"],
      fill: {
        colors: ["#fff", "#9fb87382"],
      },
      stroke: {
        curve: "smooth",
      },
      responsive: [
        {
          breakpoint: 1000,
          yaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
             
            ],
          },
          options: {
            plotOptions: {
              bar: {
                horizontal: true,
                columnWidth: "50%",
                endingShape: "rounded",
              },
            },
          },
        },
      ],
      
    },
    series: [
      {
        name: "series1",
        data: [31, 40, 28, 51, 42, 109, ],
      },
      {
        name: "series2",
        data: [11, 32, 45, 32, 34, 52, ],
      },
    ],
  });

  return (
    // <div id="chart">
    //     <ReactApexChart options={this.state.options} series={this.state.series} type="area" height={350} />
    // </div>
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="area"
      height={320}
    />
  );
}
