import React from "react";
import LeftChart from "./LeftChart";
import RightChart from "./RightChart";
import TopChart from "./TopChart";
export const Charts = () => {
  return (
    <>
      <div className="margin-left">
        <div className="container-fluid">
          <div className="row mb-4">
            <div className="col-12 px-0 mb-3 pb-2">
              <div className="default-card">
                <h5 className="card-heading">Job Cards For The Month Of September 2024</h5>
                <TopChart />
              </div>
            </div>

            <div className="col-lg-6 px-0 pe-lg-2 ps-lg-0">
              <div className="default-card">
                <h5 className="card-heading">Sales Graph</h5>
                <LeftChart />
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0 px-0 ps-lg-3 pe-lg-0">
              <div className="default-card">
                <h5 className="card-heading">Job Cards - Month Wise</h5>
                <RightChart />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
