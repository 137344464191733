import { IoArrowBack } from "react-icons/io5";
import "./ProductionManagement.css";
import React, { useEffect, useRef, useState } from "react";
import { Steps, Button, message, Input, Statistic } from "antd";
import InputSection from "./InputSection";
import PackagingInputSection from "./PackagingInputSection";
import { Link } from "react-router-dom";
const { Countdown } = Statistic;

const { Step } = Steps;
export default function StageDeskPage() {
  const deadline = Date.now() + 5 * 60 * 60 * 1000; // 5 hours in milliseconds

  const onChange = (e) => {
    console.log(e);
  };
  const [currentStep, setCurrentStep] = useState(0); // Tracks the current progress step
  const [previewStep, setPreviewStep] = useState(null); // Tracks the step to preview
  const [isCompleted, setIsCompleted] = useState(false); // Tracks if the process is completed
  const stepsContainerRef = useRef(null); // Reference to the steps container
  const [stepClass, setStepClass] = useState("step-class-1"); // Class for steps

  const steps = [
    { title: "Gas Filling", content: <InputSection onChange={onChange} /> },
    { title: "Flame Test 1", content: <InputSection onChange={onChange} /> },
    { title: "Flame Test 2", content: <InputSection onChange={onChange} /> },
    { title: "Assembly", content: <InputSection onChange={onChange} /> },
    { title: "Quality Check", content: <InputSection onChange={onChange} /> },
    { title: "Tray Shifting", content: <InputSection onChange={onChange} /> },
    {
      title: "Packaging",
      content: <PackagingInputSection onChange={onChange} />,
    },
  ];

  const nextStep = () => {
    if (currentStep < steps.length - 1) {
      const newStep = currentStep + 1; // Calculate the new step
      setCurrentStep(newStep);

      // Change class name based on the new step
      setStepClass(`step-class-${newStep + 1}`);

      // Scroll to the new step
      if (stepsContainerRef.current) {
        const currentStepElement = stepsContainerRef.current.children[newStep];
        if (currentStepElement) {
          currentStepElement.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }
    } else {
      setIsCompleted(true); // Mark as completed if the last step is reached
    }
  };

  const prevStep = () => {
    if (currentStep > 0) {
      const newStep = currentStep - 1; // Calculate the new step
      setCurrentStep(newStep);

      // Reset the step class when going back
      setStepClass(`step-class-${newStep + 1}`);

      // Scroll to the new step
      if (stepsContainerRef.current) {
        const currentStepElement = stepsContainerRef.current.children[newStep];
        if (currentStepElement) {
          currentStepElement.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }
    }
  };

  // Handle clicking on completed steps for preview
  const handleStepClick = (stepIndex) => {
    if (stepIndex <= currentStep) {
      setPreviewStep(stepIndex); // Set the preview step
      setCurrentStep(stepIndex); // Navigate to the clicked step

      // Scroll to the clicked step for better UX (optional)
      if (stepsContainerRef.current) {
        const stepElement = stepsContainerRef.current.children[stepIndex];
        if (stepElement) {
          stepElement.scrollIntoView({
            behavior: "smooth",
            inline: "center",
          });
        }
      }
    } else {
      message.info("You can only preview completed steps.");
    }
  };

  // Display content from the previewed step if it's set, otherwise show the current step content
  const displayedContent =
    previewStep !== null
      ? steps[previewStep].content
      : steps[currentStep].content;

  return (
    <div className="margin-left blank-section job-card-stage-page">
      <div className="container-fluid pt-4 pb-2">
        <div className="row pt-4 mt-4 mb-4 px-0">
          <div className="col-12 px-0">
            <div className="default-card default-card-border ">
              <div className="top-bar">
                <Link to="/job-card-list">
                  <IoArrowBack />
                </Link>
                <p>AshE-2609-R01</p>
                {currentStep >= 1 && currentStep <= 2 && (
                  <div>
                    <Countdown
                      title="This Batch is currently on hold for the remaining duration"
                      className="countdown"
                      value={deadline}
                      onFinish={() =>
                        console.log(
                          "This batch is now available for the next process"
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div>
                <div
                  className={`steps-container mb-3 ${stepClass}`}
                  ref={stepsContainerRef}
                >
                  <Steps current={currentStep} direction="horizontal">
                    {steps.map((step, index) => (
                      <Step
                        key={index}
                        title={step.title}
                        // description={`Description for ${step.title}`}
                        onClick={() => handleStepClick(index)} // Allow clicking on completed steps for preview
                        status={
                          index < currentStep || isCompleted // Mark all steps as finished if completed
                            ? "finish"
                            : index === currentStep
                            ? "process" // Mark the current step as active
                            : "wait" // Future steps
                        }
                      />
                    ))}
                  </Steps>
                </div>
                <div>{steps[currentStep].content}</div>

                <div className="d-flex gap-3 justify-content-end">
                  <Button
                    className="default-btn border-0 mt-3 btn btn-primary"
                    onClick={prevStep}
                    disabled={currentStep === 0}
                    style={{ display: currentStep === 0 ? "none" : "block" }}
                  >
                    Previous
                  </Button>
                  <Button
                    className="default-btn border-0 mt-3 btn btn-primary"
                    onClick={nextStep}
                    disabled={isCompleted} // Disable Next button if completed
                  >
                    {currentStep === steps.length - 1 ? "Finish" : "Submit"}
                  </Button>
                </div>

                {isCompleted && (
                  <div style={{ marginTop: "20px", color: "green" }}>
                    <strong>Process completed successfully!</strong>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
